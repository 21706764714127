import React, { ReactNode, useEffect, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import Footer from './Footer';
import SidePanelOptions from './SidePanelOptions';
import { setSidePanel } from '../store/slices/sidePanelSlice';
import { RootState } from '../store/store';

interface LayoutProperties {
  children: ReactNode;
  handleShareCollection?: () => void;
  noSidebar?: boolean;
  isFooter?: boolean;
  isLeftSidePanelOpen?: boolean;
}

const Layout: React.FC<LayoutProperties> = ({
  children,
  handleShareCollection,
  noSidebar,
  isFooter,
  isLeftSidePanelOpen = false,
}) => {
  const dispatch = useDispatch();
  const isSidePanelOpen = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelOpen
  );
  const isSidePanelCloseDefault = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelCloseDefault
  );
  const [lastPanelOpened, setLastPanelOpened] = useState<
    'left' | 'right' | undefined
  >();

  useEffect(() => {
    if (children) {
      if (isMobile) {
        dispatch(setSidePanel(false));
      } else if (isSidePanelCloseDefault) {
        dispatch(setSidePanel(false));
      } else {
        dispatch(setSidePanel(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, dispatch]);

  useEffect(() => {
    if (isSidePanelOpen && !isLeftSidePanelOpen) {
      setLastPanelOpened('left');
    } else if (isLeftSidePanelOpen && !isSidePanelOpen) {
      setLastPanelOpened('right');
    }
  }, [isSidePanelOpen, isLeftSidePanelOpen]);

  const getContentSize = () => {
    if (isSidePanelOpen && isLeftSidePanelOpen) {
      return 'w-full md:w-3/5';
    } else if (isSidePanelOpen || isLeftSidePanelOpen) {
      return 'w-full md:w-4/5';
    } else {
      return 'w-full';
    }
  };

  const getContentPosition = () => {
    if (isSidePanelOpen && isLeftSidePanelOpen) {
      return 'justify-center';
    } else if (isSidePanelOpen) {
      return 'justify-end';
    } else if (isLeftSidePanelOpen) {
      return 'justify-start';
    } else {
      return lastPanelOpened === 'right' ? 'justify-start' : 'justify-end';
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div
        className={`flex ${
          noSidebar ? 'justify-start' : getContentPosition()
        } h-full`}
      >
        {!noSidebar && (
          <SidePanelOptions handleShareCollection={handleShareCollection} />
        )}

        <div
          className={`transition-all duration-300 ${noSidebar ? (isLeftSidePanelOpen ? 'w-full md:w-4/5' : 'w-full') : getContentSize()}`}
        >
          <div className="p-6 pt-28 md:pt-32 pb-12">{children}</div>
        </div>
      </div>
      {!isFooter && <Footer />}
    </div>
  );
};

export default Layout;
