import React, { useEffect, useState } from 'react';

import { FiArrowUpCircle } from 'react-icons/fi';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const ScrollToTopButton: React.FC = () => {
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  return (
    <div className="relative">
      {showButton && (
        <FiArrowUpCircle
          className="text-darkBlue fixed cursor-pointer z-50 bottom-3 md:bottom-4 right-3 md:right-6 size-6 md:size-10"
          onClick={scrollToTop}
        />
      )}
    </div>
  );
};

export default ScrollToTopButton;
