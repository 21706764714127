import { useState } from 'react';

import api from '../../api/axiosInstance';
import { DeckType, Deck_DDB } from '../../types/deck';
import { User } from '../../types/user';
import { handleError } from '../../utils/errorHandler';

interface CreateDeckProperties {
  isPublic?: boolean;
  deckMode: string;
  deckName: string;
  deckFaction?: string;
  deckHero?: string;
  deckCards?: string;
  deckAllCards?: string;
  user: User;
}

interface UpdateDeckProperties {
  idDeck: number;
  isPublic?: boolean;
  isLegal?: boolean;
  deckName?: string;
  deckHero?: string;
  deckCards: string;
  user: User;
  deck: DeckType;
}

interface DeckDetailsProperties {
  idDeck: number;
  user: User;
}

export const useDeck = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Créer un deck
  const createDeck = async ({
    isPublic,
    deckMode,
    deckName,
    deckFaction,
    deckHero,
    deckCards,
    deckAllCards,
    user,
  }: CreateDeckProperties): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/decks/create',
        {
          isPublic,
          isLegal: false,
          mode: deckMode,
          name: deckName,
          faction: deckFaction,
          hero: deckHero,
          cards: deckCards,
          allCards: deckAllCards,
          createdBy: `u_${user.idUser}`,
          createdAt: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 201) {
        setIsLoading(false);
        return response.data?.idDeck;
      } else {
        setError('Erreur lors de la création du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const updateDeck = async ({
    idDeck,
    isPublic,
    isLegal,
    deckName,
    deckHero,
    deckCards,
    user,
    deck,
  }: UpdateDeckProperties): Promise<DeckType | undefined> => {
    setIsLoading(true);
    setError(undefined);
    try {
      const response = await api.put(
        '/decks/' + idDeck,
        {
          isPublic: isPublic ?? deck.isPublic,
          isLegal: isLegal ?? deck.isPublic,
          name: deckName ?? deck.name,
          faction: deck.faction,
          hero: deckHero ?? deck?.hero?.reference,
          cards: deckCards,
          createdBy: deck.createdBy,
          createdAt: deck.createdAt,
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        return response.data;
      } else {
        setError('Erreur lors de la création du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const deleteDeck = async ({
    idDeck,
    user,
  }: DeckDetailsProperties): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.delete('/decks/' + idDeck, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data;
      } else {
        setError('Erreur lors de la suppresion du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Récupérer des decks
  const getUserDecks = async (user: User): Promise<Deck_DDB[] | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/decks/user/`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as Deck_DDB[];
      } else {
        setError('Erreur lors de la récupération des decks');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const getPublicDecks = async (): Promise<Deck_DDB[] | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/decks`);

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as Deck_DDB[];
      } else {
        setError('Erreur lors de la récupération des decks');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const getDeckDetail = async ({
    user,
    idDeck,
  }: DeckDetailsProperties): Promise<Deck_DDB | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get('/decks/' + idDeck, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as Deck_DDB;
      } else {
        setError('Erreur lors de la récupération des détails du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return {
    createDeck,
    updateDeck,
    deleteDeck,
    getPublicDecks,
    getUserDecks,
    getDeckDetail,
    isLoading,
    error,
  };
};
