import React from 'react';

import { useTranslation } from 'react-i18next';

import Layout from '../parts/Layout';
import ToolsCard from '../parts/ToolsCard';

const Market: React.FC = () => {
  const { t } = useTranslation();
  document.title = t('navigation.market') + ' | Altverse';

  return (
    <Layout>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <ToolsCard
          title={t('market.unique.title')}
          desc={t('market.unique.desc')}
          label={t('market.unique.action')}
          // mediaSrc={process.env.PUBLIC_URL + "/assets/videos/cards.webm"}
          // mediaType="video"
          page="market/uniques"
        />
        <ToolsCard
          title={t('market.friends.title')}
          desc={t('market.friends.desc')}
          label={t('market.friends.action')}
          // mediaSrc={process.env.PUBLIC_URL + "/assets/videos/cards.webm"}
          // mediaType="video"
          page="market/friends"
        />
        <ToolsCard
          title={t('market.exchange.title')}
          desc={t('market.exchange.desc')}
          label={t('market.exchange.action')}
          // mediaSrc={process.env.PUBLIC_URL + "/assets/videos/cards.webm"}
          // mediaType="video"
          page="market/exchange"
        />
        <ToolsCard
          title={t('market.donation.title')}
          desc={t('market.donation.desc')}
          label={t('market.donation.action')}
          // mediaSrc={process.env.PUBLIC_URL + "/assets/videos/cards.webm"}
          // mediaType="video"
          page="market/donation"
        />
      </div>
    </Layout>
  );
};

export default Market;
