import axios from 'axios';

import { clearUser } from '../store/slices/userSlice';
import store from '../store/store';

const baseURL =
  process.env.REACT_APP_API_BASE_URL || 'https://www.api.altverse.fr/api';

export const refreshAccessToken = async (): Promise<string | undefined> => {
  const state = store.getState();
  const refreshToken = state.user.refreshToken;

  if (!refreshToken) {
    console.error('Token de rafraîchissement non trouvé');
    return undefined;
  }

  try {
    // Envoie une requête au serveur pour rafraîchir le token
    const response = await axios.post(`${baseURL}/auth/refresh`, {
      refreshToken,
    });

    if (response.status === 200) {
      const { accessToken } = response.data;

      // Mise à jour du Redux store avec le nouveau token
      store.dispatch({
        type: 'user/updateUser',
        payload: {
          ...state.user,
          accessToken,
        },
      });

      return accessToken;
    } else {
      console.error('Erreur lors de la mise à jour du token');
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // Gestion des erreurs : vérifier si le refresh token est expiré
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      console.error(
        'Le refresh token est expiré ou invalide. Déconnexion nécessaire.'
      );

      // Déconnecter l'utilisateur et effacer les informations du store
      store.dispatch(clearUser());
    } else {
      console.error(
        error.response?.data?.error || 'Erreur lors de la mise à jour du token'
      );
    }

    return undefined;
  }
};
