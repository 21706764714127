import React from 'react';

import { useTranslation } from 'react-i18next';

import ExportCards from './ExportCards';
import Button from '../components/Button';
import SmallSeparator from '../components/SmallSeparator';
import {
  exportOptions,
  exportToolsCardsOptions,
  sealedExportOptions,
} from '../data/exportData';
import { FullCard } from '../types/altCard';

interface ButtonGroupProperties {
  isExport: boolean;
  isExportSealed: boolean;
  isDonation: boolean;
  isSealed: boolean;
  isClose: boolean;
  goTo?: string;
  handleOpenDonationPages: () => void;
  handleClose?: () => void;
  handleNavigateTo?: () => void;
  filteredCards: FullCard[];
}

const ButtonGroup: React.FC<ButtonGroupProperties> = ({
  isExport,
  isExportSealed,
  isDonation,
  isSealed,
  isClose,
  goTo,
  handleOpenDonationPages,
  handleClose,
  handleNavigateTo,
  filteredCards,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-end">
      {(isExport || isExportSealed) && <SmallSeparator />}
      {(isExport || isExportSealed) && (
        <ExportCards
          cards={filteredCards}
          exportOptions={isSealed ? sealedExportOptions : exportOptions}
          exportToolsOptions={exportToolsCardsOptions}
          isSealed={isExportSealed}
        />
      )}
      {isDonation && <SmallSeparator />}
      {isDonation && (
        <Button onClick={handleOpenDonationPages}>
          {t('navigation.donation')}
        </Button>
      )}
      {isClose && <SmallSeparator />}
      {isClose && <Button onClick={handleClose}>{t('actions.close')}</Button>}
      {goTo && <SmallSeparator />}
      {goTo && (
        <Button onClick={handleNavigateTo}>{t('actions.goToProfil')}</Button>
      )}
    </div>
  );
};

export default ButtonGroup;
