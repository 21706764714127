import { useMemo } from 'react';

import {
  CONSTRUCTED_MIN_CARDS,
  FACTIONS_NAMES,
  LIMITED_MIN_CARDS,
  PARTICULE_ID_REGEX,
  RARITY,
} from '../constants/constants';
import { MODES } from '../constants/decks';
import { FullCard } from '../types/altCard';

export interface ValidationError {
  code: string;
  references?: { particuleId: string; quantity: number }[];
  factions?: { faction: string; quantity: number }[];
  nbCards?: number;
  value?: string;
}

interface UseDeckValidationResult {
  isLegal: boolean;
  errors?: ValidationError[];
}

const useDeckValidation = (
  myCards: FullCard[],
  baseDeckCards?: FullCard[],
  mode?: string
): UseDeckValidationResult => {
  const isConstructedMode = mode === MODES.CONSTRUCTED.key;
  const minCards = isConstructedMode
    ? CONSTRUCTED_MIN_CARDS
    : LIMITED_MIN_CARDS;

  const { isLegal, errors } = useMemo(() => {
    if (!baseDeckCards) return { isLegal: false, undefined };
    const errors: ValidationError[] = [];

    // 1. MIN_CARDS cartes minimum
    const totalCards = baseDeckCards.reduce(
      (sum, card) => sum + (card.quantity ?? 1),
      0
    );
    if (totalCards < minCards) {
      errors.push({
        code: 'MINIMUM_CARDS',
        value: minCards?.toString(),
      });
    }

    // 2. Vérification des héros
    const heroCards = baseDeckCards.filter((card) => card.type === 'HERO');
    if (heroCards.length > 1) {
      errors.push({
        code: 'MAX_HEROES',
        nbCards: heroCards.length,
      });
    }

    if (heroCards.length === 1) {
      const heroFaction = heroCards[0].mainFaction;
      const otherFactionCards = baseDeckCards.filter(
        (card) => card.mainFaction === heroFaction && card.type !== 'HERO'
      );

      if (otherFactionCards.length === 0) {
        errors.push({
          code: 'HERO_INVALID_FACTION',
          value: FACTIONS_NAMES[heroFaction],
        });
      }
    }

    if (isConstructedMode) {
      // 3. 15 rares maximum
      const totalRares = baseDeckCards
        .filter((card) => card.rarity === 'RARE')
        .reduce((sum, card) => sum + (card.quantity ?? 1), 0);
      if (totalRares > 15) {
        errors.push({
          code: 'MAX_RARES',
          nbCards: totalRares,
        });
      }

      // 4. 3 uniques maximum
      const totalUniques = baseDeckCards
        .filter((card) => card.rarity === 'UNIQUE')
        .reduce((sum, card) => sum + (card.quantity ?? 1), 0);
      if (totalUniques > 3) {
        errors.push({
          code: 'MAX_UNIQUES',
          nbCards: totalUniques,
        });
      }

      // 5. Maximum 3 cartes par particulId
      const particulIdMap: { [key: string]: number } = {};
      for (const card of baseDeckCards) {
        const match = card?.reference?.match(PARTICULE_ID_REGEX);
        if (match) {
          const particulId = match[0];
          particulIdMap[particulId] =
            (particulIdMap[particulId] || 0) + (card.quantity ?? 1);
        }
      }
      const invalidParticulIds: { particuleId: string; quantity: number }[] =
        [];
      Object.entries(particulIdMap).map(([id, quantity]) => {
        if (quantity > 3)
          invalidParticulIds.push({ particuleId: id, quantity: quantity });
      });

      if (invalidParticulIds.length > 0) {
        errors.push({
          code: 'MAX_PARTICULID',
          references: invalidParticulIds,
        });
      }
      // 6. Possession des cartes
      const insufficientCommonCards: {
        particuleId: string;
        quantity: number;
      }[] = [];
      const insufficientRareCards: { particuleId: string; quantity: number }[] =
        [];
      const insufficientUniqueCards: {
        particuleId: string;
        quantity: number;
      }[] = [];

      for (const deckCard of baseDeckCards) {
        const particulId =
          deckCard?.reference?.match(PARTICULE_ID_REGEX)?.[0] || '';
        const ownedCard = myCards.find(
          (myCard) => myCard.reference === deckCard.reference
        );

        const ownedQuantity =
          (ownedCard && (ownedCard.inMyCollection ?? 0)) || 0;
        const missingQuantity = (deckCard.quantity ?? 1) - ownedQuantity;

        if (missingQuantity > 0) {
          switch (deckCard.rarity) {
            case RARITY.COMMON: {
              insufficientCommonCards.push({
                particuleId: particulId,
                quantity: missingQuantity,
              });

              break;
            }
            case RARITY.RARE: {
              insufficientRareCards.push({
                particuleId: particulId,
                quantity: missingQuantity,
              });

              break;
            }
            case RARITY.UNIQUE: {
              insufficientUniqueCards.push({
                particuleId: particulId,
                quantity: 1,
              });
              break;
            }
          }
        }
      }

      if (insufficientCommonCards.length > 0) {
        errors.push({
          code: 'INSUFFICIENT_COMMON_CARDS',
          references: insufficientCommonCards,
        });
      }
      if (insufficientRareCards.length > 0) {
        errors.push({
          code: 'INSUFFICIENT_RARE_CARDS',
          references: insufficientRareCards,
        });
      }
      if (insufficientUniqueCards.length > 0) {
        errors.push({
          code: 'INSUFFICIENT_UNIQUE_CARDS',
          references: insufficientUniqueCards,
        });
      }
    } else {
      // Vérification du nombre maximum de factions (limité à 3)
      const factionMap: { [faction: string]: number } = {};

      for (const card of baseDeckCards) {
        factionMap[card.mainFaction] =
          (factionMap[card.mainFaction] || 0) + (card.quantity ?? 1);
      }

      const factions = Object.entries(factionMap);

      if (factions.length > 3) {
        errors.push({
          code: 'MAX_FACTIONS',
          factions: factions.map(([faction, quantity]) => ({
            faction: FACTIONS_NAMES[faction],
            quantity: quantity,
          })),
        });
      }
    }

    return {
      isLegal: errors.length === 0,
      errors,
    };
  }, [baseDeckCards, isConstructedMode, minCards, myCards]);
  return { isLegal, errors };
};

export default useDeckValidation;
