import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import { CARDS_PATH } from '../constants/constants';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import BackButton from '../parts/BackButton';
import CardList from '../parts/CardList';
import DiscordTuto from '../parts/DiscordTuto';
import Layout from '../parts/Layout';
import CardSelectionInputModal from '../parts/modals/CardSelectionInputModal';
import { RootState } from '../store/store';
import { BasicCard, FullCard } from '../types/altCard';
import { createCardsFromText, createTextFromCards } from '../utils/formatCards';

const DonationHelper = () => {
  const { t } = useTranslation();
  const [donationCards, setDonationCards] = useState<FullCard[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards } = useLoadCardsFromJson(CARDS_PATH, isFusionEnabled);

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = (cardInput: string) => {
    const cardEntries = cardInput.split('\n');
    const cardData: BasicCard[] = cardEntries.map((entry) => {
      const [count, reference] = entry.split(' ');
      return {
        reference,
        count: Number.parseInt(count, 10) || 0,
      } as BasicCard;
    });

    const { includedCards } = createCardsFromText(myCards, cardData);
    if (includedCards.length > 0) {
      setDonationCards(includedCards);
    }
    setModalIsOpen(false);
  };

  const addCardBack = (card: FullCard) => {
    const isSelected = donationCards?.some(
      (c) => c.reference === card.reference
    );

    if (isSelected) {
      setDonationCards(
        donationCards.filter((c) => c.reference !== card.reference)
      );
    }
  };

  document.title = t('navigation.donation') + ' | Altverse';

  return (
    <Layout>
      <div className="space-y-8">
        <BackButton to="market" title={t('market.donation.title')} />
        <div className="space-y-2">
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center items-center">
            <Button onClick={handleOpenModal}>
              {t('actions.selectCards')}
            </Button>
          </div>
          <DiscordTuto />
        </div>

        {donationCards.length > 0 && (
          <CardList
            cards={donationCards}
            addCardBack={addCardBack}
            isDonation
          />
        )}

        {modalIsOpen && (
          <CardSelectionInputModal
            isOpen={modalIsOpen}
            onClose={handleCloseModal}
            cards={myCards?.length > 0 ? myCards : allCards || []}
            currentCards={createTextFromCards(donationCards)}
          />
        )}
      </div>
    </Layout>
  );
};

export default DonationHelper;
