import React, { useEffect, useState } from 'react';

import ShinyCard from '@mountainpass/react-pokemon-cards';
import Modal from '@mui/material/Modal';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import Button from './Button';
import { LANGUAGES } from '../constants/constants';
import { FullCard } from '../types/altCard';
import { Elo } from '../types/elo';
import { getCompleteImagePath } from '../utils/cardUtilities';

interface CardModalProperties {
  card: FullCard | undefined;
  isOpen: boolean;
  onRequestClose: () => void;
}

const foilOptions = [
  'rare holo v',
  'rare holo vmax',
  'rare holo vstar',
  'rare rainbow',
  'rare rainbow alt',
  'rare secret',
  'rare ultra',
];

const defaultFoil = 'custom';

const CardModal: React.FC<CardModalProperties> = ({
  card,
  isOpen,
  onRequestClose,
}) => {
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [foil, setFoil] = useState<any>(defaultFoil);
  const [eloData, setEloData] = useState<Elo>();
  const [error, setError] = useState<boolean>(false);

  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  useEffect(() => {
    if (isOpen) {
      setFoil(defaultFoil);
      setEloData(undefined);
      if (card?.rarity === 'UNIQUE') {
        fetchEloData(card.reference);
      }
    }
  }, [isOpen, card]);

  const fetchEloData = async (cardId: string) => {
    try {
      const response = await fetch(`https://uniquesranking.com/json/${cardId}`);
      const data: Elo = await response.json();
      if (data.error > 0) {
        setError(true);
      } else {
        setEloData(data);
      }
    } catch (error) {
      console.error('Failed to fetch elo data:', error);
      setError(true);
    }
  };

  const handleDoubleClick = () => {
    const randomFoil =
      foilOptions[Math.floor(Math.random() * foilOptions.length)];
    setFoil(randomFoil);
  };

  if (!card || !isOpen) return <></>;

  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      className="fixed inset-0 flex items-center justify-center py-40 lg:py-32 px-4 bg-black bg-opacity-75"
    >
      <div className="outline-none space-y-6">
        {card.rarity === 'UNIQUE' && (
          <div className="mt-4 text-white text-center font-chillaxMedium">
            {error ? (
              <p>{t('cardModal.privateEloMessage')}</p>
            ) : eloData ? (
              <div>
                <p>
                  {t('cardModal.elo')}:{' '}
                  <strong>{eloData.elo.toFixed(2)}</strong>
                </p>
                <p>
                  {t('cardModal.averageElo')}:{' '}
                  <strong>{eloData.avg_elo.toFixed(2)}</strong>
                </p>
                <p>
                  {t('cardModal.numberOfDuels')}:{' '}
                  <strong>{eloData.nb_duel}</strong>
                </p>
              </div>
            ) : (
              <p>{t('cardModal.loadingElo')}</p>
            )}
          </div>
        )}
        <div
          onDoubleClick={handleDoubleClick}
          className="w-full flex-1 select-none border-0 outline-0"
        >
          {isMobile ? (
            <img
              src={getCompleteImagePath(card, currentLanguage)}
              alt={card.name[currentLanguage]}
              className="w-full h-auto rounded custom-shadow"
            />
          ) : (
            <ShinyCard
              rarity={foil}
              supertype="pokémon"
              subtype="basic"
              dataGallery="true"
            >
              <img
                src={getCompleteImagePath(card, currentLanguage)}
                alt={`${card.name[currentLanguage]}-holo`}
                className="rounded max-h-[500px]"
              />
            </ShinyCard>
          )}
        </div>
        {card?.owner && (
          <div className="flex flex-col text-white text-center font-chillaxMedium space-y-2">
            <Button
              onClick={() => {
                window.open(`https://www.altered.gg/profile/${card.owner}`);
              }}
            >
              {t('actions.goToProfil') + ' - ' + card.owner}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CardModal;
