import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IoFilterSharp } from 'react-icons/io5';

import ActiveFilters from '../../components/ActiveFilters';
import Button from '../../components/Button';
import NameFilterInput from '../../components/NameFilterInput';
import Separator from '../../components/Separator';
import {
  CARDS_PATH,
  LANGUAGES,
  LEGALITY,
  VISIBILITY,
} from '../../constants/constants';
import useLoadCardsFromJson from '../../hooks/useLoadCardsFromJson';
import { Deck_DDB } from '../../types/deck';
import { filterDecksByQuery } from '../../utils/deckUtilities';
import FilterDecksModal from '../modals/FilterDecksModal';

interface CardFiltersProperties {
  decks: Deck_DDB[];
  filteredDecks: Deck_DDB[];
  setFilteredDecks: (filtered: Deck_DDB[]) => void;
}

const DeckFilters: React.FC<CardFiltersProperties> = ({
  decks,
  filteredDecks,
  setFilteredDecks,
}) => {
  const { t, i18n } = useTranslation();
  const { allCards } = useLoadCardsFromJson(CARDS_PATH);
  const [deckFaction, setDeckFaction] = useState<string[]>([]);
  const [deckMode, setDeckMode] = useState<string[]>([]);
  const [deckVisibility, setDeckVisibility] = useState<string>('');
  const [deckLegality, setDeckLegality] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    let filtered = [...decks];

    if (deckFaction.length > 0) {
      filtered = filtered.filter((deck) => deckFaction.includes(deck.faction));
    }
    if (deckVisibility != '') {
      if (deckVisibility === VISIBILITY.PUBLIC) {
        filtered = filtered.filter((deck) => !!deck.isPublic);
      } else if (deckVisibility === VISIBILITY.PRIVATE) {
        filtered = filtered.filter((deck) => !deck.isPublic);
      }
    }
    if (deckLegality != '') {
      if (deckLegality === LEGALITY.LEGAL) {
        filtered = filtered.filter((deck) => !!deck.isLegal);
      } else if (deckLegality === LEGALITY.ILLEGAL) {
        filtered = filtered.filter((deck) => !deck.isLegal);
      }
    }
    if (deckMode.length > 0) {
      filtered = filtered.filter((deck) => deckMode.includes(deck.mode));
    }

    if (nameFilter.trim() !== '') {
      filtered = filterDecksByQuery(filtered, nameFilter);
    }

    setFilteredDecks(filtered);
  }, [
    deckFaction,
    deckVisibility,
    deckLegality,
    deckMode,
    decks,
    nameFilter,
    setFilteredDecks,
    currentLanguage,
    allCards,
  ]);

  const handleRemoveFilter = (type: string, value: string) => {
    switch (type) {
      case 'faction': {
        setDeckFaction(deckFaction.filter((t) => t !== value));
        break;
      }
      case 'mode': {
        setDeckMode(deckMode.filter((r) => r !== value));
        break;
      }
      case 'visibility': {
        setDeckVisibility('');
        break;
      }
      case 'legality': {
        setDeckLegality('');
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <div className="w-full space-y-16">
      <div className="relative space-y-2 md:space-y-0">
        <div className="flex justify-between items-end">
          <span className="text-darkBlue hidden lg:block font-chillaxMedium whitespace-nowrap">
            {t('status.onScreenDecks', {
              nb: filteredDecks.length,
            })}
          </span>

          <div className="flex justify-center items-center w-full h-full">
            <div className="flex w-fit rounded lg:absolute -bottom-3 right-0 justify-around">
              <div className="flex items-end">
                <NameFilterInput
                  nameFilter={nameFilter}
                  setNameFilter={setNameFilter}
                  smallFilter={false}
                  placeholder={t('filterTitle.name')}
                />
                <Button onClick={openModal} right>
                  <div className="flex justify-center items-center">
                    <IoFilterSharp className="mr-1" />
                    {t('actions.filters')}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Separator desktop />
        <div className="absolute flex space-x-0.5 w-full overflow-x-auto h-fit flex-nowrap pb-2">
          <div className="inline-flex">
            <ActiveFilters
              filters={{ type: 'faction', values: deckFaction }}
              onRemoveFilter={handleRemoveFilter}
            />
          </div>
          {deckVisibility && (
            <div className="inline-flex">
              <ActiveFilters
                filters={{ type: 'visibility', values: [deckVisibility] }}
                onRemoveFilter={handleRemoveFilter}
              />
            </div>
          )}
          {deckLegality && (
            <div className="inline-flex">
              <ActiveFilters
                filters={{ type: 'legality', values: [deckLegality] }}
                onRemoveFilter={handleRemoveFilter}
              />
            </div>
          )}
          <div className="inline-flex">
            <ActiveFilters
              filters={{ type: 'mode', values: deckMode }}
              onRemoveFilter={handleRemoveFilter}
            />
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <span className="text-darkBlue font-chillaxMedium">
          {t('status.onScreenCards', {
            nb: filteredDecks.length,
          })}
        </span>
        <Separator />
      </div>
      <FilterDecksModal
        deckFaction={deckFaction}
        setDeckFaction={setDeckFaction}
        deckVisibility={deckVisibility}
        setDeckVisibility={setDeckVisibility}
        deckLegality={deckLegality}
        setDeckLegality={setDeckLegality}
        deckMode={deckMode}
        setDeckMode={setDeckMode}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
    </div>
  );
};

export default DeckFilters;
