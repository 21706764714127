import axios from 'axios';

import { refreshAccessToken } from './refreshAccessToken';
import store from '../store/store';

const baseURL =
  process.env.REACT_APP_API_BASE_URL || 'https://www.api.altverse.fr/api';

// Créez une instance Axios
const api = axios.create({
  baseURL: baseURL,
});

// Intercepteur de requête : Ajouter le token d'accès à chaque requête
api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.user.accessToken;

    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur de réponse : Rafraîchir le token si on reçoit une erreur 401
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Vérifier si la réponse est une erreur 401 due à un token expiré
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Tenter de rafraîchir le token
      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        // Mettre à jour le token dans le store Redux
        store.dispatch({
          type: 'user/setUser',
          payload: {
            ...store.getState().user,
            accessToken: newAccessToken,
          },
        });

        // Réessayer la requête originale avec le nouveau token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      }
    }
    throw error;
  }
);

export default api;
