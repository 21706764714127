import { useState } from 'react';

import api from '../../api/axiosInstance';
import { Team, TeamDetailAPI } from '../../types/team';
import { User } from '../../types/user';
import { handleError } from '../../utils/errorHandler';

export interface CreateTeamProperties {
  teamName: string;
  user: User;
}

export interface JoinTeamProperties {
  teamCode: string;
  user: User;
}

export interface TeamIdProperties {
  idTeam: string;
  user: User;
}

export const useTeam = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Créer une équipe
  const createTeam = async ({
    teamName,
    user,
  }: CreateTeamProperties): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/teams/create',
        { name: teamName },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 201) {
        setIsLoading(false);
        return response.data.teamCode;
      } else {
        setError("Erreur lors de la création de l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Rejoindre une équipe
  const joinTeam = async ({
    teamCode,
    user,
  }: JoinTeamProperties): Promise<boolean | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/teams/join',
        { teamCode },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        return true;
      } else {
        setError("Erreur lors de la tentative de rejoindre l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Quitter une équipe
  const leaveTeam = async ({
    idTeam,
    user,
  }: TeamIdProperties): Promise<boolean | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/teams/leave',
        { idTeam },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        return true;
      } else {
        setError("Erreur lors de la tentative de quitter l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Supprimer une équipe
  const deleteTeam = async ({
    idTeam,
    user,
  }: TeamIdProperties): Promise<boolean | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.delete('/teams/' + idTeam, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return true;
      } else {
        setError("Erreur lors de la suppression de l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Récupérer toutes les équipes d'un user
  const getTeams = async (user: User): Promise<Team[] | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/teams`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as Team[];
      } else {
        setError('Erreur lors de la récupération des équipes');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Récupérer les détails d'une équipe
  const getTeamDetails = async ({
    idTeam,
    user,
  }: TeamIdProperties): Promise<TeamDetailAPI | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/teams/${idTeam}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as TeamDetailAPI;
      } else {
        setError("Erreur lors de la récupération des détails de l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return {
    createTeam,
    joinTeam,
    leaveTeam,
    deleteTeam,
    getTeamDetails,
    getTeams,
    isLoading,
    error,
  };
};
