import { FRIEND_SORT_MODE, SORT_DIRECTION } from '../constants/constants';
import { UserFriendship } from '../types/friend';

export const sortFriends = (
  friends: UserFriendship[],
  sortMode: string,
  sortDirection: string
): UserFriendship[] => {
  return friends.sort((a, b) => {
    let comparison = 0;

    const aTradeAvailable = a.tradeAvailable ?? 0;
    const bTradeAvailable = b.tradeAvailable ?? 0;

    switch (sortMode) {
      case FRIEND_SORT_MODE.BY_TRADE_CARDS: {
        const aTradeCards = a.cards ? a.cards.length : 0;
        const bTradeCards = b.cards ? b.cards.length : 0;
        comparison = aTradeCards - bTradeCards;
        break;
      }

      case FRIEND_SORT_MODE.BY_LAST_UPDATE: {
        comparison =
          new Date(a.lastUpdate).getTime() - new Date(b.lastUpdate).getTime();
        break;
      }

      case FRIEND_SORT_MODE.BY_TOTAL_TRADES: {
        comparison = aTradeAvailable - bTradeAvailable;
        break;
      }

      default: {
        comparison = 0;
      }
    }

    // Inversion du tri pour le mode descendant
    return sortDirection === SORT_DIRECTION.DESCENDING
      ? comparison
      : -comparison;
  });
};
