import { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchFriends } from '../api/fetchFriends';
import { fetchFriendTradingCard } from '../api/fetchFriendTradingCard';
import Button from '../components/Button';
import Footer from '../parts/Footer';
import Loading from '../parts/Loading';
import { setFriends, updateFriend } from '../store/slices/friendsSlice';
import { RootState } from '../store/store';
import { UserFriendship, UserFriendshipAPI } from '../types/friend';

const CollectionSecret = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [token, setToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorMessageString, setErrorMessageString] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [justUnique, setJustUnique] = useState<boolean>(false);
  const [loadingLabel, setLoadingLabel] = useState<string>('');
  const myFriends = useSelector((state: RootState) => state.friends.friends);

  const handleFetchFriends = async () => {
    setLoading(true);
    setLoadingLabel(t('loading.loadingFriends'));
    const friendsResult: UserFriendshipAPI[] = await fetchFriends(
      token,
      setErrorMessage
    );

    if (friendsResult) {
      const transformedFriendsResult: UserFriendship[] = friendsResult.map(
        (friend) => ({
          userFriend: {
            id: friend.userFriend.id,
            nickName: friend.userFriend.nickName,
            avatarPath: friend.userFriend.avatarPath,
            biography: friend.userFriend.biography,
            background: friend.userFriend.background,
            totalFriends: friend.userFriend.totalFriends,
            friendStatus: friend.userFriend.friendStatus,
            uniqueId: friend.userFriend.uniqueId,
            inMyTradelist: friend.userFriend.inMyTradelist,
          },
          friendshipId: friend.id,
          cards: friend.cards || undefined,
          lastUpdate: new Date().toISOString(),
        })
      );

      dispatch(setFriends(transformedFriendsResult));

      setLoading(false);
      setLoadingLabel('');
    } else {
      console.error('Failed to fetch friends list');
      setLoading(false);
      setLoadingLabel('');
      return;
    }

    setLoading(false);
    setLoadingLabel('');
  };

  const handleFetchFriendsCards = async () => {
    if (myFriends.length === 0) {
      setErrorMessageString('You need to fetch your Friends');
      return;
    }

    setLoading(true);
    setLoadingLabel(
      t('loading.loadingFriendsCards', {
        loadedFriends: 0,
        nbFriends: myFriends?.length,
      })
    );

    for (let index = 0; index < myFriends.length; index++) {
      const friend = myFriends[index];

      const friendsCardsResult = await fetchFriendTradingCard(
        token,
        friend.userFriend.id,
        setErrorMessage
      );

      if (friendsCardsResult) {
        const { transformedCards } = friendsCardsResult;
        const updatedFriend: UserFriendship = {
          ...friend,
          cards: justUnique ? [] : transformedCards,
          uniqueCards: [],
        };

        dispatch(updateFriend(updatedFriend));
      }

      setLoadingLabel(
        t('loading.loadingFriendsCards', {
          loadedFriends: index + 1,
          nbFriends: myFriends.length,
        })
      );
    }

    setLoadingLabel('');
    setLoading(false);
  };

  document.title = t('navigation.collection') + ' | Altverse';

  if (loading) {
    return <Loading label={loadingLabel} />;
  }

  return (
    <div className="h-screen flex flex-col justify-between">
      <div className="space-y-8 pt-32 pb-12">
        <div className="px-6 lg:px-20 space-y-2">
          <div className="flex justify-center ">
            <textarea
              rows={4}
              placeholder={t('actions.enterToken')}
              value={token}
              onChange={(event) => setToken(event.target.value)}
              className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />
          </div>
          <div className="flex justify-end space-x-2 ">
            <Button onClick={handleFetchFriends}>Charger les amis</Button>
            <Button onClick={handleFetchFriendsCards}>
              Charger les cartes des amis
            </Button>
          </div>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={justUnique}
              onChange={() => setJustUnique((unique) => !unique)}
              className="form-checkbox outline-none accent-darkBlue"
            />
            <span className="font-chillaxBold">
              {t('Seulement les uniques')}
            </span>
          </label>
          <div className="justify-center">
            <span className="text-red-600 font-chillaxMedium">
              <Trans i18nKey="warning.token" />
            </span>
          </div>
          {errorMessage && (
            <div className="flex justify-center">
              <span className="text-red-600 font-chillaxMedium">
                {errorMessage || errorMessageString}
              </span>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CollectionSecret;
