import { useState } from 'react';

import { FullCard, SortedCard } from '../types/altCard';
import { drawBoosterPack } from '../utils/boosterUtilities';

interface SealedSimulatorProperties {
  sortedCards: SortedCard | undefined;
  set: string;
  nbBooster: number;
}

const useSealedSimulator = ({
  sortedCards,
  set,
  nbBooster = 6,
}: SealedSimulatorProperties) => {
  const [openedCards, setOpenedCards] = useState<FullCard[]>([]);
  const [reserveCards, setReserveCards] = useState<FullCard[]>([]);
  const [deckCards, setDeckCards] = useState<FullCard[]>([]);
  const [opened, setOpened] = useState<boolean>(false);

  const handleOpenPacks = () => {
    let newOpenedCards: FullCard[] = [];

    for (let index = 0; index < nbBooster; index++) {
      newOpenedCards = [
        ...newOpenedCards,
        ...drawBoosterPack(sortedCards, set),
      ];
    }

    setOpenedCards(newOpenedCards);
    setReserveCards(newOpenedCards);
    setOpened(true);
  };

  const resetPacks = () => {
    setOpenedCards([]);
    setReserveCards([]);
    setDeckCards([]);
    setOpened(false);
  };

  const handleInputPacks = (cards: FullCard[]) => {
    setOpenedCards(cards);
    setReserveCards(cards);
    setDeckCards([]);
    setOpened(true);
  };

  const addDeckCard = (card: FullCard) => {
    setReserveCards((previous) => {
      const index = previous.findIndex((c) => c.reference === card.reference);
      if (index !== -1) {
        const updatedCards = [...previous];
        updatedCards.splice(index, 1);
        return updatedCards;
      }
      return previous;
    });
    setDeckCards((previous) => [...previous, card]);
  };

  const addCardBack = (card: FullCard) => {
    setDeckCards((previous) => {
      const index = previous.findIndex((c) => c.reference === card.reference);
      if (index !== -1) {
        const updatedCards = [...previous];
        updatedCards.splice(index, 1);
        return updatedCards;
      }
      return previous;
    });
    setReserveCards((previous) => [...previous, card]);
  };

  return {
    openedCards,
    reserveCards,
    sortedCards,
    deckCards,
    opened,
    setOpened,
    handleInputPacks,
    handleOpenPacks,
    resetPacks,
    addDeckCard,
    addCardBack,
  };
};

export default useSealedSimulator;
