export const CARD_TYPE: { [key: string]: string } = {
  HERO: 'HERO',
  CHARACTER: 'CHARACTER',
  SPELL: 'SPELL',
  PERMANENT: 'PERMANENT',
  TOKEN: 'TOKEN',
  FOILER: 'FOILER',
  TOKEN_MANA: 'TOKEN_MANA',
};

export const RARITY: { [key: string]: string } = {
  COMMON: 'COMMON',
  RARE: 'RARE',
  UNIQUE: 'UNIQUE',
};

export const SET: { [key: string]: string } = {
  ALL: 'all',
  BEYOND_THE_GATES: 'beyondTheGates',
  KS: 'ks',
};

export const SEALED_SET = {
  EXPLORATION: { key: SET.KS, label: 'filterTitle.ks' },
  COLLECTION: {
    key: SET.BEYOND_THE_GATES,
    label: 'filterTitle.beyondTheGates',
  },
};

export const FACTIONS: { [key: string]: string } = {
  AX: 'AX',
  BR: 'BR',
  LY: 'LY',
  MU: 'MU',
  OR: 'OR',
  YZ: 'YZ',
};

export const FACTIONS_NAMES: { [key: string]: string } = {
  AX: 'Axiom',
  BR: 'Bravos',
  LY: 'Lyra',
  MU: 'Muna',
  OR: 'Ordis',
  YZ: 'Yzmir',
};

export const FRIEND_SORT_MODE: { [key: string]: string } = {
  BY_TRADE_CARDS: 'BY_TRADE_CARDS',
  BY_LAST_UPDATE: 'BY_LAST_UPDATE',
  BY_TOTAL_TRADES: 'BY_TOTAL_TRADES',
};

export const PRIMARY_SORT_MODE: { [key: string]: string } = {
  BY_NOTHING: 'BY_NOTHING',
  BY_TYPE: 'BY_TYPE',
  BY_FACTION: 'BY_FACTION',
};

export const SECONDARY_SORT_MODE: { [key: string]: string } = {
  BY_NOTHING: 'BY_NOTHING',
  BY_LATEST_ADDITION: 'BY_LATEST_ADDITION',
  BY_MAIN_COST: 'BY_MAIN_COST',
  BY_NAME: 'BY_NAME',
  BY_NUMBER: 'BY_NUMBER',
};

export const SORT_DIRECTION: { [key: string]: string } = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const NUMBER_FILTERS: { [key: string]: string } = {
  EQUALS: 'equals',
  GREATER: 'greater',
  LESS: 'less',
};

export const LANGUAGES: { [key: string]: string } = {
  FRENCH: 'fr',
  ENGLISH: 'en',
  SPAIN: 'es',
  ITALY: 'it',
  GERMANY: 'de',
};

export const SUPPORTED_LANGUAGE: string[] = [
  LANGUAGES.FRENCH,
  LANGUAGES.ENGLISH,
  LANGUAGES.SPAIN,
  LANGUAGES.ITALY,
  LANGUAGES.GERMANY,
];

export const EXPORT_CARD_FORMAT: { [key: string]: string } = {
  ID: 'id',
  NAME: 'name',
};

export const EXPORT_FORMAT: { [key: string]: string } = {
  BASE64: 'BASE64',
  NORMAL: 'NORMAL',
  CSV: 'CSV',
};

export const MAX_HEROES = 1;
export const MIN_CARDS = 1;
export const MAX_FACTIONS = 3;

export const ENRICHED_UNIQUE_CARDS_PATH = '/data/enriched_unique_cards.json';

export const CARDS_PATH = '/data/cards.json';

export enum LOAN_SIDE {
  OWNER = 'owner',
  BORROWER = 'borrower',
}

export const PARTICULE_ID_REGEX = /([A-Z]{2}_\d+)/;
export const COLLECTION_REGEX = /^(\d+)(ALT.*)$/;

export const VISIBILITY: { [key: string]: string } = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const LEGALITY: { [key: string]: string } = {
  LEGAL: 'LEGAL',
  ILLEGAL: 'ILLEGAL',
};

export const ACTIONS: { [key: string]: string } = {
  ADD: 'add',
  REMOVE: 'remove',
};

export const DBB_SEPARATOR = ';';

export const CONSTRUCTED_MIN_CARDS = 39;
export const LIMITED_MIN_CARDS = 30;
