import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import Separator from '../../components/Separator';
import { useSharedCollection } from '../../hooks/api/useSharedCollection';
import { RootState } from '../../store/store';
import { FullCard } from '../../types/altCard';
import { getExcessCards, getMissingCards } from '../../utils/cardUtilities';
import { createTextFromCollection } from '../../utils/formatCards';
import ShareOption from '../ShareOption';

interface ShareCollectionModalProperties {
  isOpen: boolean;
  onClose: () => void;
  myCards: FullCard[];
  allCards: FullCard[];
}

const ShareCollectionModal: React.FC<ShareCollectionModalProperties> = ({
  isOpen,
  onClose,
  myCards,
  allCards,
}) => {
  const { t } = useTranslation();
  const { error, createSharedCollection } = useSharedCollection();
  const [includeMyCards, setIncludeMyCards] = useState<boolean>(false);
  const [includeMissingCards, setIncludeMissingCards] =
    useState<boolean>(false);
  const [includeExcessCards, setIncludeExcessCards] = useState<boolean>(false);

  const [myCardsRarityFilter, setMyCardsRarityFilter] = useState<string[]>([]);
  const [missingCardRarityFilter, setMissingCardRarityFilter] = useState<
    string[]
  >([]);

  const [excessCardRarityFilter, setExcessCardRarityFilter] = useState<
    string[]
  >([]);

  const user = useSelector((state: RootState) => state.user);

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );

  const handleShare = async () => {
    let myCardsText: string | undefined;
    let missingCardsText: string | undefined;
    let excessCardsText: string | undefined;

    if (includeMyCards) {
      const filteredMyCards = myCards.filter(
        (card) =>
          myCardsRarityFilter.length === 0 ||
          myCardsRarityFilter.includes(card.rarity)
      );
      myCardsText = createTextFromCollection(filteredMyCards);
    }

    if (includeMissingCards) {
      const missingCards = getMissingCards(
        myCards,
        allCards,
        rareQuantity,
        commonQuantity,
        missingCardRarityFilter.length > 0 ? missingCardRarityFilter : undefined
      );
      missingCardsText = createTextFromCollection(missingCards);
    }

    if (includeExcessCards) {
      const excessCards = getExcessCards(
        myCards,
        rareQuantity,
        commonQuantity,
        excessCardRarityFilter.length > 0 ? excessCardRarityFilter : undefined
      );
      excessCardsText = createTextFromCollection(excessCards);
    }

    const sharedId = await createSharedCollection({
      user,
      cards: myCardsText,
      missingCards: missingCardsText,
      excessCards: excessCardsText,
    });
    if (error) {
      toast.error("Une erreure c'est produite");
    } else {
      const collectionUrl = `https://altverse.fr/shared/${sharedId}`;
      navigator.clipboard.writeText(collectionUrl);
      toast.info(`${t('shared.successClipboard')}: ${collectionUrl}`);
    }

    onClose();
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={onClose}
      maxWidth="max-w-lg"
      width="w-full"
    >
      <div className="space-y-4">
        <div className="space-y-4">
          <ShareOption
            includeCards={includeMyCards}
            onIncludeChange={setIncludeMyCards}
            rarityFilter={myCardsRarityFilter}
            onRarityChange={setMyCardsRarityFilter}
            includeLabel={t('shared.mycards')}
            isUnique
          />
          <Separator />
          <ShareOption
            includeCards={includeMissingCards}
            onIncludeChange={setIncludeMissingCards}
            rarityFilter={missingCardRarityFilter}
            onRarityChange={setMissingCardRarityFilter}
            includeLabel={t('shared.missingCard')}
          />
          <Separator />
          <ShareOption
            includeCards={includeExcessCards}
            onIncludeChange={setIncludeExcessCards}
            rarityFilter={excessCardRarityFilter}
            onRarityChange={setExcessCardRarityFilter}
            includeLabel={t('shared.excessCards')}
          />
          <Separator />
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <Button onClick={onClose}>{t('actions.cancel')}</Button>
          <Button onClick={handleShare}>{t('actions.share')}</Button>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default ShareCollectionModal;
