import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { FaExclamationCircle } from 'react-icons/fa';
import { FaSave } from 'react-icons/fa';
import { FaLock, FaLockOpen } from 'react-icons/fa6';
import { IoStatsChart } from 'react-icons/io5';
import { LuFileEdit } from 'react-icons/lu';

import DeckSummary from '.././decks/DeckSummary';
import Button from '../../components/Button';
import SeparatorVertical from '../../components/SeparatorVertical';
import { LANGUAGES } from '../../constants/constants';
import { MODES } from '../../constants/decks';
import { exportOptions, exportToolsDeckOptions } from '../../data/exportData';
import i18n from '../../i18n';
import { FullCard } from '../../types/altCard';
import { DeckType } from '../../types/deck';
import BackButton from '../BackButton';
import ExportCards from '../ExportCards';
import Loading from '../Loading';

interface DeckBuilderHeaderProperties {
  deck: DeckType | undefined;
  baseDeckCards: FullCard[] | undefined;
  isDeckLoaded: boolean;
  isCreator: boolean;
  isLegal: boolean;
  handleSaveDeck: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStatsPanelIsOpen: any;
  setUpdateDeckModalIsOpen: (isOpen: boolean) => void;
}
const DeckBuilderHeader = ({
  deck,
  baseDeckCards,
  isDeckLoaded,
  isCreator,
  isLegal,
  handleSaveDeck,
  setStatsPanelIsOpen,
  setUpdateDeckModalIsOpen,
}: DeckBuilderHeaderProperties) => {
  const { t } = useTranslation();

  const handleToggleStatsPanel = () =>
    setStatsPanelIsOpen((isOpen: boolean) => !isOpen);
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const isConstructedMode = deck?.mode === MODES.CONSTRUCTED.key;

  if (!isDeckLoaded) {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.decks') + ' | Altverse';

  const Actions = (
    <div className="flex space-x-2 items-center text-darkBlue">
      {isLegal !== undefined && (
        <span className={`text-xl md:text-3xl`}>
          {isLegal ? (
            <FaCheckCircle className="text-green-600" />
          ) : (
            <FaExclamationCircle className="text-red-600" />
          )}
        </span>
      )}
      <span
        className={`text-xl md:text-3xl font-chillaxBold px-2 rounded whitespace-nowrap`}
      >
        {deck?.isPublic ? <FaLockOpen /> : <FaLock />}
      </span>
      <span className="w-2 h-1 rounded bg-darkBlue" />
      {isCreator && (
        <Button small onClick={() => setUpdateDeckModalIsOpen(true)}>
          <div className="flex space-x-2 justify-center items-center w-full">
            <LuFileEdit />
            <p>{t('actions.modify')}</p>
          </div>
        </Button>
      )}
      <Button small onClick={handleToggleStatsPanel}>
        <div className="flex space-x-2 justify-center items-center w-full">
          <IoStatsChart />
          <p>{t('actions.advancedStats')}</p>
        </div>
      </Button>
    </div>
  );

  return (
    <div className="space-y-4">
      <BackButton
        to="decks"
        title={`${deck?.hero ? deck?.hero?.name?.[currentLanguage] + ' / ' : ''} ${deck?.name}`}
        actions={Actions}
      />
      <div
        className={`flex flex-row items-center justify-center ${isConstructedMode ? 'h-24' : 'h-44'} w-full space-x-8`}
      >
        <DeckSummary cards={baseDeckCards} />
        <SeparatorVertical />
        <div className="flex flex-col space-y-2">
          {isCreator && (
            <Button onClick={handleSaveDeck} full>
              <div className="flex space-x-2 justify-center items-center w-full">
                <FaSave />
                <p>{t('actions.saveDeck')}</p>
              </div>
            </Button>
          )}
          {deck && baseDeckCards && (
            <ExportCards
              cards={deck.hero ? [deck.hero, ...baseDeckCards] : baseDeckCards}
              exportOptions={exportOptions}
              exportToolsOptions={exportToolsDeckOptions}
              full
              isSealed={deck?.mode === MODES.SEALED.key}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeckBuilderHeader;
