import { useState } from 'react';

import api from '../../api/axiosInstance';
import { User } from '../../types/user';
import { handleError } from '../../utils/errorHandler';

export const useCollection = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Mise à jour de la collection de cartes
  const updateCollection = async (
    collectionText: string,
    user: User
  ): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      await api.put(
        `/collections`,
        { collectionText },
        {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      setIsLoading(false);
    } catch (error) {
      handleError(error, setError);

      setIsLoading(false);
    }
  };

  // Récupération de la collection de cartes
  const getCollection = async (user: User): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/collections`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });

      setIsLoading(false);
      return response.data.cards;
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return { updateCollection, getCollection, isLoading, error };
};
