import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchDeck } from '../../api/fetchDeck';
import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import Separator from '../../components/Separator';
import { CARDS_PATH } from '../../constants/constants';
import { HERO, MODES } from '../../constants/decks';
import { useDeck } from '../../hooks/api/useDeck';
import useDeckBuilder from '../../hooks/useDeckBuilder';
import useLoadCardsFromJson from '../../hooks/useLoadCardsFromJson';
import i18n from '../../i18n';
import { RootState } from '../../store/store';
import { FullCard } from '../../types/altCard';
import { getCardByReference } from '../../utils/cardUtilities';
import {
  createTextFromCollection,
  formatTextForDB,
} from '../../utils/formatCards';
import { getNavigateUrl } from '../../utils/getNavigateUrl';

interface CreateTeamModalProperties {
  isOpen: boolean;
  closeModal: () => void;
  isLimited?: boolean;
  limitedCards?: string;
  limtedAllCards?: string;
}

const CreateDeckModal: React.FC<CreateTeamModalProperties> = ({
  isOpen,
  closeModal,
  isLimited,
  limitedCards,
  limtedAllCards,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { recreateDeckFromText, baseDeckCards } = useDeckBuilder();

  const [deckName, setDeckName] = useState<string>('');
  const [deckMode, setDeckMode] = useState<string>(
    isLimited ? MODES.SEALED.key : MODES.CONSTRUCTED.key
  );
  const [deckHero, setDeckHero] = useState<FullCard>();
  const [deckAllCards, setDeckAllCards] = useState<string>(
    limtedAllCards || ''
  );
  const [loadingDeck, setLoadingDeck] = useState<boolean>(false);
  const [deckFaction, setDeckFaction] = useState<string>();
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const { allCards } = useLoadCardsFromJson(CARDS_PATH, true);

  const [deckLink, setDeckLink] = useState<string>('');

  const { createDeck, isLoading } = useDeck();

  const needHero =
    deckMode !== MODES.SEALED.key && deckMode !== MODES.DRAFT.key;

  const user = useSelector((state: RootState) => state.user);

  const handleModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDeckMode(event.target.value);
    setDeckHero(undefined);
    setDeckFaction(undefined);
  };

  const handleHeroChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHero =
      allCards && getCardByReference(event.target.value, allCards);

    if (selectedHero) {
      setDeckHero(selectedHero);
      setDeckFaction(selectedHero.mainFaction);
    }
  };

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'Private') {
      setIsPublic(false);
    } else if (selectedValue === 'Public') {
      setIsPublic(true);
    } else {
      setIsPublic(false);
    }
  };

  const handleCardInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDeckAllCards(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (deckName && baseDeckCards) {
      const idDeck = await createDeck({
        deckName,
        deckHero: deckHero?.reference,
        deckMode,
        deckFaction,
        deckCards:
          limitedCards || createTextFromCollection(baseDeckCards, true),
        deckAllCards: deckAllCards && formatTextForDB(deckAllCards),
        isPublic,
        user,
      });

      if (idDeck) {
        navigate(getNavigateUrl(i18n.language, 'decks/' + idDeck));
      }
    }
  };

  const handleSubmitImport = async (event: React.FormEvent) => {
    setLoadingDeck(true);
    event.preventDefault();
    const deckDetails = await fetchDeck(
      deckLink,
      (event) => console.error(event) //TODO, gérer l'erreur dans l'interface
    );
    if (deckDetails && allCards) {
      setDeckName(deckDetails.name);
      setDeckHero(getCardByReference(deckDetails.hero, allCards));
      setDeckFaction(deckDetails.faction);
      await recreateDeckFromText(deckDetails.cards);
    }
    setLoadingDeck(false);
  };

  useEffect(() => {
    const selectedHero =
      allCards && getCardByReference('ALT_CORE_B_MU_03_C', allCards);
    if (selectedHero && deckMode === MODES.CONSTRUCTED.key) {
      setDeckHero(selectedHero);
      setDeckFaction(selectedHero.mainFaction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCards, deckMode]);

  return (
    <ModalTemplate isOpen={isOpen} closeModal={closeModal}>
      {user?.idUser ? (
        <div className="md:w-[400px] space-y-8">
          {!isLimited && (
            <div className="space-y-2">
              <h2 className="text-xl font-chillaxBold">
                {t('deck.importDeckAltered')}
              </h2>
              <form onSubmit={handleSubmitImport}>
                <div className="flex flex-col space-y-4">
                  <input
                    type="text"
                    id="importDeckLink"
                    value={deckLink}
                    placeholder={t('deck.importDeckPlaceholder')}
                    onChange={(event) => setDeckLink(event.target.value)}
                    className="block w-full shadow sm:text-sm rounded-md h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                  />
                  <Button full isSubmit disabled={isLoading}>
                    {t('actions.import')}
                  </Button>
                  <Separator />
                </div>
              </form>
            </div>
          )}

          {loadingDeck ? (
            <div className="flex justify-center items-center w-full h-full">
              <h3 className="font-chillaxBold text-darkBlue">
                {t('loading.loadingDeck')}
              </h3>
            </div>
          ) : (
            <div className="space-y-2">
              <h2 className="text-xl font-chillaxBold">
                {t('deck.createDeck')}
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col space-y-4">
                  <input
                    type="text"
                    id="createDeckName"
                    value={deckName}
                    placeholder={t('deck.createDeckPlaceholder')}
                    onChange={(event) => setDeckName(event.target.value)}
                    className="block w-full shadow sm:text-sm rounded-md h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                  />
                  <div>
                    <select
                      id="createDeckHero"
                      value={deckMode}
                      disabled={isLimited}
                      onChange={handleModeChange}
                      className={`block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular ${!isLimited && 'cursor-pointer'} outline-none bg-lightBlue border-darkBlue`}
                    >
                      <option value="" disabled>
                        {t('deck.selectModePlaceholder')}
                      </option>
                      {Object.values(MODES).map((mode) => (
                        <option key={mode.key} value={mode.key}>
                          {t(mode.label)}
                        </option>
                      ))}
                    </select>
                  </div>

                  {needHero && (
                    <div>
                      <select
                        id="createDeckHero"
                        value={deckHero?.reference}
                        onChange={handleHeroChange}
                        className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
                      >
                        <option value="" disabled>
                          {t('deck.selectHeroPlaceholder')}
                        </option>
                        {Object.values(HERO).map((hero) => (
                          <option key={hero.reference} value={hero.reference}>
                            {t(hero.label)}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {deckMode === MODES.SEALED.key && (
                    <div>
                      <textarea
                        rows={4}
                        disabled={isLimited}
                        placeholder={t('actions.enterYourCards')}
                        value={deckAllCards}
                        onChange={handleCardInputChange}
                        className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                      />
                    </div>
                  )}

                  <select
                    id="createDeckTeam"
                    value={isPublic ? 'Public' : 'Private'}
                    onChange={handleTeamChange}
                    className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
                  >
                    <option value="Private">{t('deck.private')}</option>
                    <option value="Public">{t('deck.public')}</option>
                  </select>

                  <Button isSubmit disabled={isLoading}>
                    {t('actions.create')}
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <p className="text-red-500 font-chillaxBold">
            {t('teams.needConection')}
          </p>
        </div>
      )}
    </ModalTemplate>
  );
};

export default CreateDeckModal;
