import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ModalTemplate from '../../components/ModalTemplate';
import { CARDS_PATH } from '../../constants/constants';
import { TABS_SHARED_COMPARE_CARDS } from '../../constants/tabs';
import useLoadCardsFromJson from '../../hooks/useLoadCardsFromJson';
import useMyCards from '../../hooks/useMyCards';
import { RootState } from '../../store/store';
import { FullCard } from '../../types/altCard';
import {
  compareCardCollections,
  getExcessCards,
  getMissingCards,
} from '../../utils/cardUtilities';
import CardList from '../CardList';
import Tabs from '../Tabs';

interface CompareModalProperties {
  isOpen: boolean;
  onClose: () => void;
  sharedMissingCards: FullCard[];
  sharedExcessCards: FullCard[];
}

const CompareModal: React.FC<CompareModalProperties> = ({
  isOpen,
  onClose,
  sharedMissingCards,
  sharedExcessCards,
}) => {
  const { t } = useTranslation();
  const [myMissingForHim, setMyMissingForHim] = useState<FullCard[]>([]);
  const [hisExcessForMe, setHisExcessForMe] = useState<FullCard[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('');

  const { fusionCards: myCards } = useMyCards();

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards } = useLoadCardsFromJson(CARDS_PATH, isFusionEnabled);

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );

  const myMissingCards =
    myCards &&
    allCards &&
    getMissingCards(myCards, allCards, rareQuantity, commonQuantity);
  const myExcessCards =
    myCards && getExcessCards(myCards, rareQuantity, commonQuantity);

  useEffect(() => {
    if (
      sharedMissingCards.length > 0 &&
      myExcessCards.length > 0 &&
      myMissingForHim.length === 0
    ) {
      const matchingForHim = compareCardCollections(
        sharedMissingCards,
        myExcessCards
      );
      setHisExcessForMe(matchingForHim);
    }

    if (
      myMissingCards &&
      sharedExcessCards.length > 0 &&
      myMissingCards.length > 0 &&
      hisExcessForMe.length === 0
    ) {
      const matchingForMe = compareCardCollections(
        sharedExcessCards,
        myMissingCards
      );
      setMyMissingForHim(matchingForMe);
    }

    if (selectedTab === '') {
      if (myMissingForHim.length > 0) {
        setSelectedTab(TABS_SHARED_COMPARE_CARDS.MY_MISSING.key);
      } else if (hisExcessForMe.length > 0) {
        setSelectedTab(TABS_SHARED_COMPARE_CARDS.SHARED_MISSING.key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedMissingCards, sharedExcessCards]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={onClose}
      width="lg:w-2/3"
      height="h-5/6"
    >
      <div>
        {Object.keys(TABS_SHARED_COMPARE_CARDS).length > 0 ? (
          <>
            <Tabs
              tabs={TABS_SHARED_COMPARE_CARDS}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              full
            />
            {selectedTab === TABS_SHARED_COMPARE_CARDS.MY_MISSING.key && (
              <CardList cards={myMissingForHim} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_COMPARE_CARDS.SHARED_MISSING.key && (
              <CardList cards={hisExcessForMe} showCollectionFilter />
            )}
          </>
        ) : (
          <p className="text-center">{t('shared.noExchange')}</p>
        )}
      </div>
    </ModalTemplate>
  );
};

export default CompareModal;
