import axios from 'axios';

import { handleError } from '../utils/errorHandler';

interface DeckInfos {
  name: string;
  faction: string;
  hero: string;
  cards: string;
}

interface CardIndexesApi {
  [key: string]: number;
}

function transformCardsIndexToText(cardDict: CardIndexesApi): string {
  return Object.entries(cardDict)
    .map(([key, value]) => {
      const cleanKey = key.includes('_U_')
        ? key.replace(/\/cards\//, '')
        : key.replace(/\/cards\//, '').replaceAll('_COREKS_', '_CORE_');
      return `${value} ${cleanKey}`;
    })
    .join('\n');
}

export const fetchDeck = async (
  deckLink: string,
  setErrorMessage?: (error: string) => void
): Promise<DeckInfos | undefined> => {
  const idDeck = deckLink.split('decks/')[1];
  try {
    const response = await axios.get(
      'https://api.altered.gg/deck_user_lists/' + idDeck
    );
    return {
      name: response.data.name as string,
      faction: response.data.faction.reference as string,
      hero: response.data.alterator.reference
        .replace(/\/cards\//, '')
        .replaceAll('_COREKS_', '_CORE_') as string,
      cards: transformCardsIndexToText(response.data.cardIndexes) as string,
    };
  } catch (error) {
    console.error('Error fetching deck:', error);
    handleError(error, setErrorMessage);
    return undefined;
  }
};
