import { useDispatch } from 'react-redux';

import { cleanCollectionCards } from '../../store/slices/collectionCardsSlice';
import { clearUser } from '../../store/slices/userSlice';
import { AppDispatch } from '../../store/store';

export const useLogout = () => {
  const dispatch: AppDispatch = useDispatch();

  const logout = (): void => {
    dispatch(clearUser());
    dispatch(cleanCollectionCards());
  };

  return { logout };
};
