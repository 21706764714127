import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from './Button';
import ModalTemplate from './ModalTemplate';

interface ButtonWithConfirmationProperties {
  onConfirm: () => void;
  children: React.ReactNode;
  confirmText: string;
  cancelText: string;
}

const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProperties> = ({
  onConfirm,
  children,
  confirmText,
  cancelText,
}) => {
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenConfirmationModal = () => {
    setConfirmationModalIsOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
  };

  const handleConfirm = () => {
    setConfirmationModalIsOpen(false);
    onConfirm();
  };

  return (
    <>
      <Button onClick={handleOpenConfirmationModal} right isVertical>
        {children}
      </Button>
      <ModalTemplate
        isOpen={confirmationModalIsOpen}
        closeModal={handleCloseConfirmationModal}
        maxWidth="max-w-lg"
        width="w-full"
      >
        <h2 className="text-xl font-chillaxBold mb-4">
          {t('actions.confirmTitle')}
        </h2>
        <p className="font-chillaxMedium max-w-lg">
          {t('actions.confirmText')}
        </p>
        <div className="flex justify-end space-x-4 mt-6">
          <Button onClick={handleCloseConfirmationModal}>{cancelText}</Button>
          <Button onClick={handleConfirm}>{confirmText}</Button>
        </div>
      </ModalTemplate>
    </>
  );
};

export default ButtonWithConfirmation;
