import { createCardsFromText } from './formatCards';
import { fetchUniqueCards } from '../api/fetchUniqueCards';
import {
  COLLECTION_REGEX,
  DBB_SEPARATOR,
  RARITY,
} from '../constants/constants';
import { FetchUniqueCardsResult } from '../hooks/api/useUniques';
import { setAwaitingCards } from '../store/slices/collectionCardsSlice';
import { AppDispatch } from '../store/store';
import { BasicCard, FullCard, UniqueCard_DDB } from '../types/altCard';

interface RecreateProperties {
  text: string;
  allCards: FullCard[];
  separator: string;
  onProgressUpdate: (advancement: string) => void;
  getUniqueCards: (uniqueCards: BasicCard[]) => Promise<FetchUniqueCardsResult>;
  uploadUniqueCards: (uniqueCards: UniqueCard_DDB[]) => Promise<void>;
  dispatch: AppDispatch;
  myCards?: FullCard[];
  token?: string;
  isRepeat?: boolean;
}

interface completeUniqueCardsProperties {
  uniqueCards: UniqueCard_DDB[];
  fullCardData: FullCard[];
  isCollection: boolean;
  myUniques?: FullCard[];
}

const parseMultiLangString = (string_?: string) => {
  const [en, fr, es, it, de] = string_?.split(DBB_SEPARATOR) || [];
  return {
    en: en || '',
    fr: fr || '',
    es: es || '',
    it: it || '',
    de: de || '',
  };
};

const completeUniqueCards = ({
  uniqueCards,
  fullCardData,
  isCollection,
  myUniques,
}: completeUniqueCardsProperties): FullCard[] => {
  return uniqueCards
    .map((uniqueCard) => {
      const [, particleId] =
        uniqueCard.reference.match(/_([A-Z]{2}_\d+)_/) || [];
      if (!particleId) return;

      const matchingCard = fullCardData.find((card) =>
        card.reference.includes(particleId)
      );

      const matchingCollection =
        myUniques &&
        myUniques.find((card) => card.reference === uniqueCard.reference);

      if (!matchingCard) return;

      const modifiedCollectorNumberPrinted =
        matchingCard.collectorNumberPrinted.replace(/-[^-]+$/, '-U');

      const fullCard: FullCard = {
        reference: uniqueCard.reference,
        type: matchingCard.type,
        subtypes: matchingCard.subtypes,
        mainFaction: uniqueCard.faction,
        rarity: RARITY.UNIQUE,
        name: matchingCard.name,
        imagePath: parseMultiLangString(uniqueCard.imagePath),
        collectorNumberPrinted: modifiedCollectorNumberPrinted,
        MAIN_COST: uniqueCard.mainCost.toString(),
        RECALL_COST: uniqueCard.recallCost.toString(),
        FOREST_POWER: uniqueCard.forestPower.toString(),
        OCEAN_POWER: uniqueCard.oceanPower.toString(),
        MOUNTAIN_POWER: uniqueCard.mountainPower.toString(),
        MAIN_EFFECT: uniqueCard.mainEffect
          ? parseMultiLangString(uniqueCard.mainEffect)
          : matchingCard.MAIN_EFFECT,
        ECHO_EFFECT: uniqueCard.echoEffect
          ? parseMultiLangString(uniqueCard.echoEffect)
          : matchingCard.ECHO_EFFECT || undefined,
        inMyCollection:
          matchingCollection?.inMyCollection || isCollection ? 1 : 0,
      };

      return fullCard;
    })
    .filter((card): card is FullCard => card !== null);
};

export const recreateCards = async ({
  text,
  allCards,
  separator,
  onProgressUpdate,
  getUniqueCards,
  uploadUniqueCards,
  myCards,
  dispatch,
  token,
  isRepeat,
}: RecreateProperties): Promise<FullCard[] | undefined> => {
  const cardEntries = text.split(separator);

  const cardData: BasicCard[] = cardEntries
    .map((entry) => {
      const match = entry.match(COLLECTION_REGEX);

      if (match) {
        const [, count, reference] = match;

        return {
          reference,
          count: Number.parseInt(count, 10),
        } as BasicCard;
      }
      return;
    })
    .filter((card): card is BasicCard => card !== null);

  if (allCards) {
    const { includedCards, uniqueCards } = createCardsFromText(
      allCards,
      cardData,
      myCards,
      isRepeat
    );

    if (uniqueCards.length > 0) {
      const { incompleteUniqueCards, failedUniques } =
        await getUniqueCards(uniqueCards);
      let allIncompleteUniques = [...incompleteUniqueCards];
      if (failedUniques.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { publicUniqueCards, privateUniqueCardStats } =
          await fetchUniqueCards({
            uniqueCardStats: failedUniques,
            onProgressUpdate,
            isPublic: true,
            token: token,
          });
        if (publicUniqueCards.length > 0) {
          uploadUniqueCards(publicUniqueCards);
        }
        dispatch(setAwaitingCards(privateUniqueCardStats));
        allIncompleteUniques = [...incompleteUniqueCards, ...publicUniqueCards];
      }

      if (allIncompleteUniques.length > 0) {
        const uniqueCards = completeUniqueCards({
          uniqueCards: allIncompleteUniques,
          fullCardData: allCards,
          isCollection: !myCards,
          myUniques: myCards?.filter((card) => card.rarity === RARITY.UNIQUE),
        });
        const retrievedCards: FullCard[] = [...includedCards, ...uniqueCards];
        return retrievedCards;
      } else {
        return includedCards;
      }
    } else {
      return includedCards;
    }
  }
  return undefined;
};
