import React from 'react';

import ModalTemplate from '../../components/ModalTemplate';
import { FullCard } from '../../types/altCard';
import CardList from '../CardList';

interface CardSelectionModalProperties {
  isOpen: boolean;
  onClose: () => void;
  cards: FullCard[];
  selectedCards: FullCard[];
  setSelectedCards: (cards: FullCard[]) => void;
  myCollection?: FullCard[];
  quantity?: boolean;
  faction?: string;
}

const CardSelectionModal: React.FC<CardSelectionModalProperties> = ({
  isOpen,
  onClose,
  cards,
  selectedCards,
  setSelectedCards,
  quantity = false,
  faction,
}) => {
  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={onClose}
      width="lg:w-2/3"
      height="h-5/6"
    >
      <div className="h-full overflow-y-scroll">
        <CardList
          cards={cards}
          setSelectedCards={setSelectedCards}
          handleClose={onClose}
          selectedCards={selectedCards}
          quantity={quantity}
          faction={faction}
          isExchange
          isClose
        />
      </div>
    </ModalTemplate>
  );
};

export default CardSelectionModal;
