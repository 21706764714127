import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BasicCard, FullCard } from '../../types/altCard';

interface CollectionCardsState {
  cards: FullCard[];
  awaitingCards: BasicCard[];
  favoriteCards: FullCard[];
}

const initialState: CollectionCardsState = {
  cards: [],
  awaitingCards: [],
  favoriteCards: [],
};

const collectionCardsSlice = createSlice({
  name: 'collectionCards',
  initialState,
  reducers: {
    setCollectionCards(state, action: PayloadAction<FullCard[]>) {
      state.cards = action.payload;
    },
    setAwaitingCards(state, action: PayloadAction<BasicCard[]>) {
      state.awaitingCards = action.payload;
    },
    cleanCollectionCards(state) {
      state.cards = [];
      state.awaitingCards = [];
    },
    addFavoriteCard(state, action: PayloadAction<FullCard>) {
      state.favoriteCards = state.favoriteCards
        ? [...state.favoriteCards, action.payload]
        : [action.payload];
    },
    removeFavoriteCard(state, action: PayloadAction<FullCard>) {
      const cardToRemove = action.payload;
      state.favoriteCards = state.favoriteCards.filter(
        (favoriteCard) => favoriteCard.reference !== cardToRemove.reference
      );
    },
  },
});

export const {
  setCollectionCards,
  setAwaitingCards,
  cleanCollectionCards,
  addFavoriteCard,
  removeFavoriteCard,
} = collectionCardsSlice.actions;

export default collectionCardsSlice.reducer;
