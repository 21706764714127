import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import BiomeStats from '../../components/BiomeStats';
import Button from '../../components/Button';
import RampStats from '../../components/RampStats';
import Separator from '../../components/Separator';
import { FullCard } from '../../types/altCard';

interface DeckSidePanelProperties {
  cards?: FullCard[];
  isOpen: boolean;
  closeSidePanel?: () => void;
}

const DeckSidePanel = ({
  cards,
  isOpen,
  closeSidePanel,
}: DeckSidePanelProperties) => {
  const { t } = useTranslation();

  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isOpen) {
      timeout = setTimeout(() => setIsContentVisible(true), 150);
    } else {
      setIsContentVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  return (
    <div
      className={`fixed right-0 top-0 transition-all duration-300 z-40 pt-16 pb-8 ${
        isOpen ? 'w-full md:w-1/5' : 'w-0'
      } shadow-xl overflow-hidden border-l-4 border-darkBlue bg-beige`}
      style={{ height: '100vh' }}
    >
      {isContentVisible && (
        <div className="relative flex flex-col space-y-10 md:justify-between h-full pt-10 pb-20 p-4 md:pt-4 md:pb-4 font-chillaxMedium text-xs md:text-base">
          <div className="flex flex-col justify-center items-center space-y-2">
            <div className="w-full space-y-2">
              <Button onClick={closeSidePanel} full>
                {t('actions.close')}
              </Button>
              <Separator />
            </div>
            {cards && (
              <>
                <div className="h-60 w-full">
                  <BiomeStats data={cards} isLegendSmall />
                </div>
                <div className="h-40 w-full">
                  <RampStats data={cards} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DeckSidePanel;
