import { useState } from 'react';

import api from '../../api/axiosInstance';
import { BasicCard, UniqueCard_DDB } from '../../types/altCard';
import { handleError } from '../../utils/errorHandler';

export interface FetchUniqueCardsResult {
  incompleteUniqueCards: UniqueCard_DDB[];
  failedUniques: BasicCard[];
}
export const useUniqueCards = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUniqueCards = async (
    uniqueCards: BasicCard[]
  ): Promise<FetchUniqueCardsResult> => {
    setIsLoading(true);
    setError(undefined);

    const incompleteUniqueCards: UniqueCard_DDB[] = [];
    const failedUniques: BasicCard[] = [];

    try {
      await Promise.all(
        uniqueCards.map(async (unique) => {
          try {
            const response = await api.get<UniqueCard_DDB>(
              `/uniques/${unique.reference}`
            );
            if (response.status === 200) {
              incompleteUniqueCards.push(response.data);
            } else {
              failedUniques.push(unique);
            }
          } catch {
            failedUniques.push(unique);
          }
        })
      );
    } catch (error_) {
      handleError(error_, setError);
    } finally {
      setIsLoading(false);
    }

    return { incompleteUniqueCards, failedUniques };
  };

  const uploadUniqueCards = async (
    uniqueCards: UniqueCard_DDB[],
    onProgressUpdate?: (message: string) => void
  ): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      await Promise.all(
        uniqueCards.map(async (card, index) => {
          try {
            const response = await api.post('/uniques/create', card);

            if (response.status === 201) {
              onProgressUpdate &&
                onProgressUpdate(
                  `Uploaded ${index + 1}/${uniqueCards.length} cards successfully`
                );
            } else {
              console.error(`Failed to upload card: ${card.reference}`);
            }
          } catch (error_) {
            console.error(`Error uploading card ${card.reference}:`, error_);
          }
        })
      );
    } catch (error_) {
      handleError(error_, setError);
    } finally {
      setIsLoading(false);
    }
  };

  return { getUniqueCards, uploadUniqueCards, isLoading, error };
};
