import { useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import Button from '../components/Button';
import DeckValidationBanner from '../components/DeckValidationBanner';
import { TABS_DECKS_VIEW } from '../constants/tabs';
import useDeckBuilder from '../hooks/useDeckBuilder';
import DeckBuilderHeader from '../parts/decks/DeckBuilderHeader';
import DeckBuilderHeaderMobile from '../parts/decks/DeckBuilderHeaderMobile';
import DeckCardList from '../parts/decks/DeckCardList';
import DeckHorizontalCardList from '../parts/decks/DeckHorizontalCardList';
import DeckSidePanel from '../parts/decks/DeckSidePanel';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import CardSelectionInputModal from '../parts/modals/CardSelectionInputModal';
import ChangeRarityModal from '../parts/modals/ChangeRarityModal';
import ModifyDeckModal from '../parts/modals/ModifyDeckModal';
import SelectRarityModal from '../parts/modals/SelectRarityModal';
import Tabs from '../parts/Tabs';
import { createTextFromCards } from '../utils/formatCards';

const DeckBuilder = () => {
  const {
    isDeckLoaded,
    isCreator,
    isLegal,
    errors,
    deck,
    baseDeckCards,
    deckCards,
    selectedAction,
    selectedParticuleID,
    allCardsWithUniques,
    cardsModalIsOpen,
    rarityModalIsOpen,
    changeRarityModalIsOpen,
    setBaseDeckCards,
    handleCloseModal,
    setChangeRarityModalIsOpen,
    handleSaveDeck,
    setRarityModalIsOpen,
    addDeckParticleId,
    removeDeckParticleId,
    changeCardRarity,
    setCardsModalIsOpen,
    updateDeckState,
    handleCardAction,
  } = useDeckBuilder();

  const { t } = useTranslation();
  const [statsPanelIsOpen, setStatsPanelIsOpen] = useState(false);
  const [updateDeckModalIsOpen, setUpdateDeckModalIsOpen] = useState(false);
  const [selectedViewTab, setSelectedViewTab] = useState<string>(
    TABS_DECKS_VIEW.LIST.key
  );

  if (!isDeckLoaded) {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.decks') + ' | Altverse';

  return (
    <Layout noSidebar isFooter isLeftSidePanelOpen={statsPanelIsOpen}>
      <div className="space-y-2 md:space-y-6">
        {isCreator && (
          <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full h-10 z-40 bg-beige border-x-4 border-r-darkBlue">
            <div className="relative flex justify-center w-full">
              <div className="absolute -top-5 w-5/6">
                <Button onClick={() => setCardsModalIsOpen(true)} full isShadow>
                  <div className="flex space-x-2 justify-center items-center w-full">
                    <FaPlus />
                    <p>{t('selectCard.title')}</p>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
        {isMobile ? (
          <DeckBuilderHeaderMobile
            deck={deck}
            baseDeckCards={baseDeckCards}
            isDeckLoaded={isDeckLoaded}
            isCreator={isCreator}
            isLegal={isLegal}
            handleSaveDeck={handleSaveDeck}
            setStatsPanelIsOpen={setStatsPanelIsOpen}
            setUpdateDeckModalIsOpen={setUpdateDeckModalIsOpen}
          />
        ) : (
          <DeckBuilderHeader
            deck={deck}
            baseDeckCards={baseDeckCards}
            isDeckLoaded={isDeckLoaded}
            isCreator={isCreator}
            isLegal={isLegal}
            handleSaveDeck={handleSaveDeck}
            setStatsPanelIsOpen={setStatsPanelIsOpen}
            setUpdateDeckModalIsOpen={setUpdateDeckModalIsOpen}
          />
        )}

        <Tabs
          tabs={TABS_DECKS_VIEW}
          selectedTab={selectedViewTab}
          setSelectedTab={setSelectedViewTab}
          bottom
        />
        {baseDeckCards && (
          <DeckValidationBanner
            allCards={allCardsWithUniques}
            isLegal={isLegal}
            errors={errors}
          />
        )}
        {selectedViewTab === TABS_DECKS_VIEW.GRID.key && (
          <DeckCardList
            allCards={allCardsWithUniques}
            cards={deckCards}
            errors={errors}
            isCreator={isCreator}
            addDeckParticulId={addDeckParticleId}
            removeDeckParticulId={removeDeckParticleId}
            changeRarity={changeCardRarity}
          />
        )}
        {selectedViewTab === TABS_DECKS_VIEW.LIST.key && (
          <DeckHorizontalCardList
            cards={deckCards}
            isCreator={isCreator}
            addDeckParticulId={addDeckParticleId}
            removeDeckParticulId={removeDeckParticleId}
            changeRarity={changeCardRarity}
          />
        )}
      </div>

      <DeckSidePanel
        cards={baseDeckCards}
        isOpen={statsPanelIsOpen}
        closeSidePanel={() => setStatsPanelIsOpen(false)}
      />

      {cardsModalIsOpen && (
        <CardSelectionInputModal
          isOpen={cardsModalIsOpen}
          onClose={handleCloseModal}
          cards={deck?.allCards || allCardsWithUniques}
          currentCards={baseDeckCards && createTextFromCards(baseDeckCards)}
          faction={deck?.faction}
          quantity
        />
      )}

      {updateDeckModalIsOpen && deck && (
        <ModifyDeckModal
          deck={deck}
          isLegal={isLegal}
          isOpen={updateDeckModalIsOpen}
          closeModal={() => setUpdateDeckModalIsOpen(false)}
          updateDeckState={updateDeckState}
        />
      )}

      {baseDeckCards && deck && (
        <>
          {rarityModalIsOpen && (
            <SelectRarityModal
              isOpen={rarityModalIsOpen}
              closeModal={() => setRarityModalIsOpen(false)}
              handleCardAction={handleCardAction}
              baseDeckCards={baseDeckCards}
              selectedParticuleID={selectedParticuleID}
              selectedAction={selectedAction}
              allCards={deck?.allCards || allCardsWithUniques}
              deck={deck}
            />
          )}

          {changeRarityModalIsOpen && (
            <ChangeRarityModal
              isOpen={changeRarityModalIsOpen}
              closeModal={() => setChangeRarityModalIsOpen(false)}
              setBaseDeckCards={setBaseDeckCards}
              selectedParticuleID={selectedParticuleID}
              allCards={deck?.allCards || allCardsWithUniques}
              baseDeckCards={baseDeckCards}
              deck={deck}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default DeckBuilder;
