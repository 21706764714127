import axios from 'axios';

import { DBB_SEPARATOR } from '../constants/constants';
import { BasicCard, UniqueCard_DDB } from '../types/altCard';
import { CardAPI } from '../types/cardApi';

interface FetchUniqueCardsResult {
  publicUniqueCards: UniqueCard_DDB[];
  privateUniqueCardStats: BasicCard[];
}

interface FetchUniqueCardsProperties {
  uniqueCardStats: BasicCard[];
  onProgressUpdate: (advancement: string) => void;
  isPublic: boolean;
  token?: string;
}

export const fetchUniqueCards = async ({
  uniqueCardStats,
  onProgressUpdate,
  isPublic,
  token,
}: FetchUniqueCardsProperties): Promise<FetchUniqueCardsResult> => {
  const publicDataUniqueCards: UniqueCard_DDB[] = [];
  const privateUniqueCardStats: BasicCard[] = [];

  onProgressUpdate && onProgressUpdate(`0/${uniqueCardStats.length}`);

  for (const stat of uniqueCardStats) {
    try {
      const translations = await fetchCardTranslations(stat.reference, token);

      if (!translations) {
        privateUniqueCardStats.push(stat);
        continue;
      }

      const formattedCard = formatCardForDDB(translations, isPublic);
      publicDataUniqueCards.push(formattedCard);

      onProgressUpdate &&
        onProgressUpdate(
          `${publicDataUniqueCards.length}/${uniqueCardStats.length}`
        );
    } catch {
      privateUniqueCardStats.push(stat);
    }
  }
  return {
    publicUniqueCards: publicDataUniqueCards,
    privateUniqueCardStats,
  };
};

// Fonction pour récupérer les traductions d'une carte dans toutes les langues
const fetchCardTranslations = async (
  reference: string,
  token?: string
): Promise<Record<string, CardAPI> | undefined> => {
  const languages = ['en-en', 'fr-fr', 'es-es', 'it-it', 'de-de'];
  const translations: Record<string, CardAPI> = {};

  for (const lang of languages) {
    try {
      const response = await axios.get(
        `https://api.altered.gg/cards/${reference}?locale=${lang}`,
        {
          headers: token ? { Authorization: `Bearer ${token}` } : undefined,
        }
      );
      translations[lang] = response.data;
    } catch (error) {
      console.error(
        `Erreur lors de la récupération de la langue ${lang} pour ${reference}:`,
        error
      );
      return;
    }
  }

  return translations;
};

// Fonction pour formater une carte au format UniqueCard_DDB
const formatCardForDDB = (
  translations: Record<string, CardAPI>,
  isPublic: boolean
): UniqueCard_DDB => {
  const cardEn = translations['en-en'];

  const imagePath = Object.keys(translations)
    .map(
      (lang) =>
        translations[lang].imagePath.split('/').pop()?.replace('.jpg', '') || ''
    )
    .join(DBB_SEPARATOR);

  const mainEffectValues = Object.keys(translations).map(
    (lang) => translations[lang].elements.MAIN_EFFECT || ''
  );
  const mainEffect = mainEffectValues.some((value) => value.trim())
    ? mainEffectValues.join(DBB_SEPARATOR)
    : undefined;

  const echoEffectValues = Object.keys(translations).map(
    (lang) => translations[lang].elements.ECHO_EFFECT || ''
  );
  const echoEffect = echoEffectValues.some((value) => value.trim())
    ? echoEffectValues.join(DBB_SEPARATOR)
    : undefined;

  return {
    reference: cardEn.reference,
    isPublic: isPublic,
    faction: cardEn.mainFaction.reference,
    imagePath,
    mainCost: Number.parseInt(cardEn.elements.MAIN_COST, 10),
    recallCost: Number.parseInt(cardEn.elements.RECALL_COST, 10),
    forestPower: Number.parseInt(cardEn.elements.FOREST_POWER, 10),
    mountainPower: Number.parseInt(cardEn.elements.MOUNTAIN_POWER, 10),
    oceanPower: Number.parseInt(cardEn.elements.OCEAN_POWER, 10),
    mainEffect,
    echoEffect,
  };
};
