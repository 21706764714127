import React from 'react';

import Block from '../../components/Block';
import { FullCard } from '../../types/altCard';
import { calculateSummary } from '../../utils/cardUtilities';

interface DeckSummaryProperties {
  cards?: FullCard[];
  small?: boolean;
}

const flexFaction = (faction: string, summaryNb?: number) => {
  return (
    <div className="flex flex-col items-center w-fit relative">
      <span className="absolute text-white font-bold top-0">{summaryNb}</span>
      <img
        src={`/assets/factions/${faction}.webp`}
        alt={faction + 'banner'}
        className="max-h-28 object-cover"
      />
    </div>
  );
};

const flexRarity = (rarity: string, summaryNb?: number) => {
  return (
    <div className="flex flex-col items-center w-fit space-y-2">
      <span className="text-xl">{summaryNb}</span>
      <img
        src={`/assets/rarity/${rarity}.png`}
        alt={rarity + 'banner'}
        className="w-14 object-cover"
      />
    </div>
  );
};

const DeckSummary: React.FC<DeckSummaryProperties> = ({ cards, small }) => {
  const summary = cards && calculateSummary(cards);

  return (
    <div className="grid auto-rows-min space-y-2">
      <div className="flex items-end justify-center space-x-2">
        <div className="flex flex-col justify-center space-y-2">
          <div className="flex items-center space-x-2">
            <Block
              number={summary?.total}
              label="gameElements.cards"
              isFit={small}
            />
            {!small && <div className="h-1 min-w-3 rounded bg-darkBlue" />}
            <Block
              number={summary?.characters}
              label="gameElements.characters"
              isFit={small}
            />
            <Block
              number={summary?.spells}
              label="gameElements.spells"
              isFit={small}
            />
            <Block
              number={summary?.permanents}
              label="gameElements.permanents"
              isFit={small}
            />
            {!!summary?.nbFaction && summary?.nbFaction <= 1 && (
              <div className="hidden md:flex rounded shadow-md justify-center items-center p-2 h-fit min-w-40 border-4 border-darkBlue bg-white">
                <div className="flex space-x-1 items-end font-chillaxMedium">
                  {flexRarity('COMMON', summary?.common)}
                  {flexRarity('RARE', summary?.rare)}
                  {flexRarity('UNIQUE', summary?.unique)}
                </div>
              </div>
            )}
          </div>
          {!!summary?.nbFaction && summary?.nbFaction > 1 && (
            <div className="hidden md:flex space-x-2">
              <div className="flex rounded shadow-md justify-center items-center p-2 h-fit min-w-40 border-4 border-darkBlue bg-white">
                <div className="flex space-x-1 items-end font-chillaxMedium">
                  {flexRarity('COMMON', summary?.common)}
                  {flexRarity('RARE', summary?.rare)}
                  {flexRarity('UNIQUE', summary?.unique)}
                </div>
              </div>
              <div className="flex rounded shadow-md justify-center items-center w-52 p-2 h-18 border-4 border-darkBlue bg-white">
                {flexFaction('AXIOM', summary?.ax)}
                {flexFaction('BRAVOS', summary?.br)}
                {flexFaction('LYRA', summary?.ly)}
                {flexFaction('MUNA', summary?.mu)}
                {flexFaction('ORDIS', summary?.or)}
                {flexFaction('YZMIR', summary?.yz)}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center md:hidden space-x-2">
        {!!summary?.nbFaction && (
          <div className="flex rounded shadow-md justify-center p-2 h-fit space-x-1 items-end font-chillaxMedium border-4 border-darkBlue bg-white">
            {flexRarity('COMMON', summary?.common)}
            {flexRarity('RARE', summary?.rare)}
            {flexRarity('UNIQUE', summary?.unique)}
          </div>
        )}
        {!!summary?.nbFaction && summary?.nbFaction > 1 && (
          <div className="rounded shadow-md flex justify-center items-center px-1 h-full border-4 border-darkBlue bg-white">
            {flexFaction('AXIOM', summary?.ax)}
            {flexFaction('BRAVOS', summary?.br)}
            {flexFaction('LYRA', summary?.ly)}
            {flexFaction('MUNA', summary?.mu)}
            {flexFaction('ORDIS', summary?.or)}
            {flexFaction('YZMIR', summary?.yz)}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeckSummary;
