import axios from 'axios';

import { UserFriendshipAPI } from '../types/friend';
import { handleError } from '../utils/errorHandler';

export const fetchFriends = async (
  token: string,
  setErrorMessage: (error: string) => void
): Promise<UserFriendshipAPI[]> => {
  try {
    const response = await axios.get(
      'https://api.altered.gg/user_friendships',
      {
        params: {
          itemsPerPage: 1000,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'fr-fr',
        },
      }
    );
    return response.data['hydra:member'] as UserFriendshipAPI[];
  } catch (error) {
    console.error('Error fetching friends:', error);
    handleError(error, setErrorMessage);
    return [];
  }
};
