import { COLLECTION_REGEX, PARTICULE_ID_REGEX } from '../constants/constants';
import { HERO } from '../constants/decks';
import { FullCard } from '../types/altCard';
import { DeckCardsType, Deck_DDB } from '../types/deck';

export const getTotalCardCount = (deck: DeckCardsType): number => {
  return deck.cards.reduce((total, card) => total + (card.quantity || 1), 0);
};

function getParticleId(card: FullCard) {
  const particleIdMatch = card.reference?.match(PARTICULE_ID_REGEX);
  const particleId = particleIdMatch ? particleIdMatch[0] : '';
  return particleId;
}

function addCardToDeck(cardMap: { [key: string]: FullCard[] }, card: FullCard) {
  const particleId = getParticleId(card);
  if (particleId) {
    if (cardMap[particleId]) {
      cardMap[particleId].push(card);
    } else {
      cardMap[particleId] = [card];
    }
  }
}

export function createDeckCards(cards: FullCard[]): DeckCardsType[] {
  const cardMap: { [key: string]: FullCard[] } = {};

  for (const card of cards) {
    addCardToDeck(cardMap, card);
  }

  const rarityOrder: { [key in 'COMMON' | 'RARE' | 'UNIQUE']: number } = {
    COMMON: 3,
    RARE: 2,
    UNIQUE: 1,
  };

  return Object.entries(cardMap).map(([particleId, cards]) => ({
    particleId,
    cards: cards.sort((a, b) => {
      const rarityA = rarityOrder[a.rarity as keyof typeof rarityOrder] || 0;
      const rarityB = rarityOrder[b.rarity as keyof typeof rarityOrder] || 0;
      return rarityA - rarityB;
    }),
  }));
}

const removeAccents = (string_: string): string => {
  return string_?.normalize('NFD').replaceAll(/[\u0300-\u036F]/g, '');
};

export const filterDecksByQuery = (
  decks: Deck_DDB[],
  query: string
): Deck_DDB[] => {
  const nameTerms: string[] = [];
  const effectTerms: string[] = [];

  const regex = /"([^"]+)"|(\S+)/g;
  let match;

  while ((match = regex.exec(query)) !== null) {
    if (match[1]) {
      effectTerms.push(match[1].toLowerCase());
    } else if (match[2]) {
      nameTerms.push(match[2].toLowerCase());
    }
  }

  return decks.filter((deck) => {
    const cardName = removeAccents(deck.name?.toLowerCase());

    const matchesName = nameTerms.every((term) =>
      cardName?.includes(removeAccents(term))
    );

    return matchesName;
  });
};

export const findFirstHero = (cards: string): string | undefined => {
  const cardList = cards.split(';');
  const heroReferences = new Set(
    Object.values(HERO).map((hero) => hero.reference)
  ); // Récupère toutes les références des héros

  // Trouve le premier héros dans la liste de cartes
  const foundHero = cardList.find((card) => heroReferences.has(card.slice(1)));
  if (foundHero) {
    const match = foundHero.match(COLLECTION_REGEX);
    if (match) {
      const reference = match[2];
      return reference;
    }
  }

  return;
};
