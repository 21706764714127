import { useState } from 'react';

import { t } from 'i18next';
import { useDispatch } from 'react-redux';

import { useUniqueCards } from './api/useUniques';
import useLoadCardsFromJson from './useLoadCardsFromJson';
import { CARDS_PATH, DBB_SEPARATOR } from '../constants/constants';
import { AppDispatch } from '../store/store';
import { FullCard } from '../types/altCard';
import { recreateCards } from '../utils/recreateCards';

interface recreateCardsProperties {
  text: string;
  myCards?: FullCard[];
  token?: string;
  isRepeat?: boolean;
}

const useAltCards = (loadingMessage: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLabel, setLoadingLabel] = useState<string>('');
  const dispatch: AppDispatch = useDispatch();
  const { getUniqueCards, uploadUniqueCards } = useUniqueCards();

  const { allCards } = useLoadCardsFromJson(CARDS_PATH);

  const onProgressUpdate = (advancement: string) => {
    setLoadingLabel(`${t(loadingMessage)} ${advancement}`);
  };

  const recreateCardsFromText = async ({
    text,
    myCards,
    token,
    isRepeat,
  }: recreateCardsProperties): Promise<FullCard[] | undefined> => {
    if (!allCards || !text) return;
    const cleanedText = text
      .replaceAll(/\n+/g, DBB_SEPARATOR)
      .replaceAll(/\s+/g, '');
    const cards = await recreateCards({
      text: cleanedText,
      allCards,
      separator: DBB_SEPARATOR,
      onProgressUpdate,
      getUniqueCards,
      uploadUniqueCards,
      myCards,
      dispatch,
      token,
      isRepeat,
    });

    if (cards) {
      return cards;
    }

    return;
  };

  return {
    loadingLabel,
    loading,
    setLoading,
    onProgressUpdate,
    recreateCardsFromText,
  };
};

export default useAltCards;
