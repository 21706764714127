import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User, UserAPI } from '../../types/user';

const initialState: User = {
  idUser: 0,
  email: '',
  pseudo: '',
  roles: '',
  accessToken: '',
  refreshToken: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserAPI>) {
      const userAPI = action.payload.user;
      state.idUser = userAPI.idUser;
      state.email = userAPI.email;
      state.pseudo = userAPI.pseudo;
      state.roles = userAPI.roles;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    updateUser(state, action: PayloadAction<User>) {
      return action.payload;
    },
    clearUser() {
      return initialState;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
