import React from 'react';

import { useTranslation } from 'react-i18next';

import Layout from '../parts/Layout';
import ToolsCard from '../parts/ToolsCard';

const Tools: React.FC = () => {
  const { t } = useTranslation();
  document.title = t('navigation.tools') + ' | Altverse';

  return (
    <Layout>
      <div className=" flex justify-center items-center">
        <ToolsCard
          title={t('tools.sealed.title')}
          desc={t('tools.sealed.desc')}
          label={t('tools.sealed.action')}
          page="tools/sealed"
        />
      </div>
    </Layout>
  );
};

export default Tools;
