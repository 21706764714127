import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import Button from '../components/Button';
import Separator from '../components/Separator';
import { CARDS_PATH, SET } from '../constants/constants';
import { TABS_SEALED } from '../constants/tabs';
import useAltCards from '../hooks/useAltCards';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import useSealedSimulator from '../hooks/useSealedSimulator';
import BackButton from '../parts/BackButton';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import CreateDeckModal from '../parts/modals/CreateDeckModal';
import SealedActions from '../parts/SealedActions';
import Tabs from '../parts/Tabs';
import {
  createTextFromCards,
  createTextFromCollection,
} from '../utils/formatCards';

const Sealed: React.FC = () => {
  const { t } = useTranslation();
  const [list, setList] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(TABS_SEALED.DRAW.key);
  const [nbBooster, setNbBooster] = useState<number>(7);
  const [set, setSet] = useState<string>(SET.BEYOND_THE_GATES);
  const { recreateCardsFromText } = useAltCards('loading.loadingDeck');
  const { sortedCards, allCards, loading, error } = useLoadCardsFromJson(
    CARDS_PATH,
    false,
    set
  );
  const {
    opened,
    openedCards,
    reserveCards,
    deckCards,
    setOpened,
    handleOpenPacks,
    handleInputPacks,
    resetPacks,
    addDeckCard,
    addCardBack,
  } = useSealedSimulator({ sortedCards, set, nbBooster });

  useEffect(() => {
    const fetchSealedCards = async () => {
      const parameters = new URLSearchParams(location.search);
      const cardsFromUrl = parameters.get('cards');
      if (cardsFromUrl && allCards) {
        const temporaryCards = await recreateCardsFromText({
          text: cardsFromUrl,
          isRepeat: true,
        });
        if (temporaryCards && temporaryCards.length > 0) {
          handleInputPacks(temporaryCards);
          setOpened(true);
        }
      }
    };
    fetchSealedCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, allCards]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const hasCoreKSCard = event.target.value.includes('_COREKS_');
    const newSet = hasCoreKSCard ? SET.KS : SET.BEYOND_THE_GATES;

    setSet(newSet);
    setList(event.target.value);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleValidate = async () => {
    if (list && allCards) {
      const temporaryCards = await recreateCardsFromText({
        text: list,
        isRepeat: true,
      });
      if (temporaryCards && temporaryCards?.length > 0)
        handleInputPacks(temporaryCards);
    }
  };

  const selectView = () => {
    if (selectedTab === TABS_SEALED.DRAW.key) {
      return (
        <CardList
          cards={reserveCards}
          isSealed
          isExport
          addDeckCard={addDeckCard}
        />
      );
    } else if (selectedTab === TABS_SEALED.DECK.key) {
      return (
        <CardList
          cards={deckCards}
          isSealed
          isExport
          addCardBack={addCardBack}
        />
      );
    }
  };

  document.title = t('navigation.sealedGenerator') + ' | Altverse';

  if (loading) {
    return <Loading label={t('loading.loadingCards')} />;
  }

  if (error) {
    return <p className="text-xl text-red-500">{error}</p>;
  }

  return (
    <Layout>
      <div className="space-y-8">
        <BackButton to="tools" title={t('tools.sealed.title')} />
        <div className="space-y-4 lg:space-y-8 pt-6 lg:pt-10">
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center items-center">
            <SealedActions
              opened={opened}
              handleOpenPacks={handleOpenPacks}
              resetPacks={resetPacks}
              nbBooster={nbBooster}
              setNbBooster={setNbBooster}
              set={set}
              setSet={setSet}
            />
            <span className="font-chillaxBold text-darkBlue">
              {t('connecter.or')}
            </span>
            <div className="flex justify-center w-full md:w-1/3">
              <textarea
                rows={4}
                placeholder={t('actions.enterCards')}
                value={list}
                onChange={handleInputChange}
                className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
              />
              <Button onClick={handleValidate} isVertical right>
                {t('actions.validate')}
              </Button>
            </div>
          </div>
          {opened && (
            <>
              <div>
                <Separator />
                <div className="flex w-full justify-center items-center">
                  <Button onClick={handleOpenModal} bottom>
                    <div className="flex space-x-2 justify-center items-center w-full">
                      <FaPlus />
                      <p> {t('deck.createDeck')}</p>
                    </div>
                  </Button>
                </div>
              </div>
              <Tabs
                tabs={TABS_SEALED}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                isSealed
                full
              />

              <div className="grid grid-cols-1 lg:grid-cols-2">
                <div className="lg:hidden">{selectView()}</div>
                <div className="hidden lg:block">
                  <CardList
                    cards={reserveCards}
                    isExport
                    isSealed
                    addDeckCard={addDeckCard}
                  />
                </div>
                <div className="border-l-4 border-darkBlue hidden lg:block">
                  <CardList
                    cards={deckCards}
                    isExportSealed
                    isSealed
                    isDeck
                    addCardBack={addCardBack}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {modalIsOpen && (
        <CreateDeckModal
          isOpen={modalIsOpen}
          closeModal={handleCloseModal}
          limitedCards={createTextFromCollection(deckCards)}
          limtedAllCards={createTextFromCards(openedCards)}
          isLimited
        />
      )}
    </Layout>
  );
};

export default Sealed;
