import React from 'react';

import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';

import {
  factionData,
  legalityData,
  modeData,
  visibilityData,
} from '../data/filtersData';

interface ActiveFiltersProperties {
  filters: { type: string; values: string[] };
  onRemoveFilter: (type: string, value: string) => void;
}

const getLabel = (type: string, value: string): string => {
  switch (type) {
    case 'faction': {
      return factionData.find((item) => item.value === value)?.label || value;
    }
    case 'visibility': {
      return (
        visibilityData.find((item) => item.value === value)?.label || value
      );
    }
    case 'legality': {
      return legalityData.find((item) => item.value === value)?.label || value;
    }
    case 'mode': {
      return modeData.find((item) => item.value === value)?.label || value;
    }
    default: {
      return value;
    }
  }
};

const ActiveFilters: React.FC<ActiveFiltersProperties> = ({
  filters,
  onRemoveFilter,
}) => {
  const { t } = useTranslation();

  return (
    <div className="inline-flex mt-4 space-x-0.5">
      {filters.values.map((value) => (
        <div
          key={value}
          className="flex items-center px-3 py-1 rounded-full text-sm space-x-2 text-lightBlue bg-darkBlue"
        >
          <p className="font-chillaxMedium">
            {t(getLabel(filters.type, value))}
          </p>
          <button
            className="text-red-500"
            onClick={() => onRemoveFilter(filters.type, value)}
          >
            <CgClose />
          </button>
        </div>
      ))}
    </div>
  );
};

export default ActiveFilters;
