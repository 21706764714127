import React, { useState } from 'react';

import { t } from 'i18next';

import CardModal from '../../components/CardModal';
import CostSpan from '../../components/CostSpan';
import ElementSpan from '../../components/ElementSpan';
import { CARD_TYPE, LANGUAGES } from '../../constants/constants';
import i18n from '../../i18n';
import { FullCard } from '../../types/altCard';
import { getAssetUrls, getCompleteImagePath } from '../../utils/cardUtilities';
import { extractSmallReference } from '../../utils/common';

interface HorizontalCardDisplayProperties {
  card: FullCard;
  quantity: number;
  isLast?: boolean;
  isFirst?: boolean;
  isCreator?: boolean;
}

const HorizontalCardDisplay: React.FC<HorizontalCardDisplayProperties> = ({
  card,
  quantity,
  isLast,
  isFirst,
  isCreator,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const fullArt = getAssetUrls(
    extractSmallReference(card.reference),
    card.rarity
  );
  const cardImage = getCompleteImagePath(card, currentLanguage);

  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <div
        className={`flex items-center justify-between ${isLast ? 'rounded-b-md' : 'border-b-2 border-darkBlue'} ${!isCreator && isFirst && 'rounded-t-md'} shadow-md cursor-pointer relative h-20 bg-cover bg-no-repeat text-white`}
        onClick={() => setModalIsOpen(true)}
        style={{
          backgroundImage: `url(${fullArt || cardImage})`,
          backgroundPositionY: `${fullArt ? '35%' : '35%'}`,
        }}
      >
        <div
          className={`flex items-center h-full p-2 md:px-4 w-full space-x-2 md:space-x-4 ${isLast && 'rounded-b-md'} ${!isCreator && isFirst && 'rounded-md'} bg-darkBlue50`}
        >
          {card.type !== CARD_TYPE.HERO &&
            card?.MAIN_COST &&
            card?.RECALL_COST && (
              <CostSpan
                mainCost={card.MAIN_COST}
                recallCost={card.RECALL_COST}
              />
            )}
          <div className="flex flex-col ml-4 overflow-hidden w-3/5">
            <span className="text-base md:text-lg font-chillaxBold whitespace-nowrap overflow-hidden text-ellipsis">
              {card.name[currentLanguage]}
            </span>
            <span className="text-sm font-chillaxMedium uppercase">
              {t(`gameElements.${card.type}`)}
            </span>
          </div>
        </div>
        <div className="absolute flex justify-center -top-1 w-full h-6">
          {card.rarity && (
            <img
              src={`/assets/rarity/${card.rarity}.png`}
              alt={`${card.rarity}-icon`}
              className="h-4 object-cover"
            />
          )}
        </div>
        <div className="absolute top-0 right-1 md:right-4 flex flex-col justify-between items-end h-full p-1">
          {card.type !== CARD_TYPE.HERO && (
            <div
              className={`${quantity > (card.inMyCollection || 0) ? 'text-red-200' : 'text-green-200'} bg-darkBlue w-fit rounded-full px-2 py-1 text-xs font-chillaxBold`}
            >
              {quantity}/{card.inMyCollection || 0}
            </div>
          )}
          <div className="flex space-x-2">
            {card?.FOREST_POWER && (
              <ElementSpan number={card.FOREST_POWER} color="bg-forest" />
            )}
            {card?.MOUNTAIN_POWER && (
              <ElementSpan number={card.MOUNTAIN_POWER} color="bg-mountain" />
            )}
            {card?.OCEAN_POWER && (
              <ElementSpan number={card.OCEAN_POWER} color="bg-ocean" />
            )}
          </div>
        </div>
      </div>
      <div className="absolute z-50">
        <CardModal
          card={card}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      </div>
    </>
  );
};

export default HorizontalCardDisplay;
