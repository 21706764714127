import React, { useState } from 'react';

import { t } from 'i18next';
import { FaFileExport } from 'react-icons/fa6';

import { FullCard } from '../types/altCard';
import { ExportOption } from '../types/export';
import ExportModal from './modals/ExportModal';
import Button from '../components/Button';

export interface ExportCardsProperties {
  cards: FullCard[];
  exportOptions: ExportOption[];
  exportToolsOptions: ExportOption[];
  isSealed?: boolean;
  isMobile?: boolean;
  full?: boolean;
  small?: boolean;
}

const ExportCards: React.FC<ExportCardsProperties> = ({
  cards,
  exportOptions,
  exportToolsOptions,
  isSealed,
  isMobile,
  full,
  small,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div>
      <Button full={full} small={small} onClick={openModal}>
        <div className="flex space-x-2 justify-center items-center w-full">
          <FaFileExport />
          {!isMobile && <p className="">{t('actions.export')}</p>}
        </div>
      </Button>
      <ExportModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        cards={cards}
        exportOptions={exportOptions}
        exportToolsOptions={exportToolsOptions}
        isSealed={isSealed}
      />
    </div>
  );
};

export default ExportCards;
