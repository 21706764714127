import { ExportOption } from '../types/export';
import {
  handleAlteredTgcBuilder,
  handleCopyToClipboard,
  handleExportIDs,
  handleExportToAlteredDB,
  handleExportToExaltsTable,
  handleExportToExchangeTool,
  handleLinkCopyToClipboard,
  handleSimulatorFirstHand,
} from '../utils/exportUtilities';

export const exportOptions: ExportOption[] = [
  { handler: handleCopyToClipboard, translationKey: 'export.copyClipboard' },
  { handler: handleExportIDs, translationKey: 'export.exportTXT' },
];

export const sealedExportOptions: ExportOption[] = [
  { handler: handleCopyToClipboard, translationKey: 'export.copyClipboard' },
  { handler: handleExportIDs, translationKey: 'export.exportTXT' },
  { handler: handleLinkCopyToClipboard, translationKey: 'export.link' },
];

export const exportToolsCardsOptions: ExportOption[] = [
  {
    handler: handleExportToAlteredDB,
    translationKey: 'export.exportAlteredDB',
  },
  {
    handler: handleAlteredTgcBuilder,
    translationKey: 'export.exportTGCBuilder',
  },
];

export const exportToolsDeckOptions: ExportOption[] = [
  {
    handler: handleExportToAlteredDB,
    translationKey: 'export.exportAlteredDB',
  },
  {
    handler: handleExportToExaltsTable,
    translationKey: 'export.exportExaltsTable',
  },
  {
    handler: handleSimulatorFirstHand,
    translationKey: 'export.exportFirstHand',
  },
];

export const exportMyCardsOptions: ExportOption[] = [
  { handler: handleCopyToClipboard, translationKey: 'export.copyClipboard' },
  { handler: handleExportIDs, translationKey: 'export.exportTXT' },
  {
    handler: handleExportToExchangeTool,
    translationKey: 'export.exportExchangeTool',
  },
  {
    handler: handleExportToAlteredDB,
    translationKey: 'export.exportAlteredDB',
  },
];
