import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import { FaLock, FaLockOpen } from 'react-icons/fa6';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { TbCardsFilled } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Separator from '../../components/Separator';
import WhiteContainer from '../../components/WhiteContainer';
import { CARDS_PATH, LANGUAGES } from '../../constants/constants';
import { MODES } from '../../constants/decks';
import { useDeck } from '../../hooks/api/useDeck';
import useLoadCardsFromJson from '../../hooks/useLoadCardsFromJson';
import i18n from '../../i18n';
import { RootState } from '../../store/store';
import { FullCard } from '../../types/altCard';
import { Deck_DDB } from '../../types/deck';
import {
  calculateTotalTextCards,
  getCardByReference,
} from '../../utils/cardUtilities';
import { extractNumber } from '../../utils/common';
import { findFirstHero } from '../../utils/deckUtilities';
import { getNavigateUrl } from '../../utils/getNavigateUrl';

interface DeckPresentationProperties {
  baseDeck: Deck_DDB;
  refreshDecks: (idDeck: number) => void;
}

const DeckPresentation: React.FC<DeckPresentationProperties> = ({
  baseDeck,
  refreshDecks,
}) => {
  const navigate = useNavigate();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const user = useSelector((state: RootState) => state.user);
  const { deleteDeck } = useDeck();
  const [hero, setHero] = useState<FullCard>();
  const [mode, setMode] = useState<string>();
  const totalCards = calculateTotalTextCards(baseDeck.cards);
  const { allCards } = useLoadCardsFromJson(CARDS_PATH, true);

  const isCreator: boolean =
    !!baseDeck?.createdBy &&
    user?.idUser === extractNumber(baseDeck?.createdBy);

  useEffect(() => {
    const heroInCards = findFirstHero(baseDeck.cards) || '';

    if (baseDeck.idDeck && allCards) {
      setHero(getCardByReference(baseDeck.hero || heroInCards, allCards));
      const mode = Object.values(MODES).find(
        (mode) => mode.key === baseDeck.mode
      );
      setMode(mode?.label);
    }
  }, [baseDeck.idDeck, allCards, baseDeck.hero, baseDeck.cards, baseDeck.mode]);

  const handleOnDeckClick = (idDeck: number) => {
    navigate(getNavigateUrl(i18n.language, 'decks/' + idDeck));
  };

  const handleDeleteDeck = (event: React.FormEvent) => {
    event.stopPropagation();
    deleteDeck({ user, idDeck: baseDeck?.idDeck });
    if (refreshDecks) refreshDecks(baseDeck?.idDeck);
  };

  const createBackgroundImage = () => {
    if (hero) {
      const backgroundImage = `https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/${hero?.reference?.slice(0, -2)}/${hero?.reference}_HERO_THUMB.jpg`;
      return backgroundImage;
    }
  };

  return (
    <>
      {baseDeck && (
        <WhiteContainer>
          <div
            className="cursor-pointer flex w-full h-full bg-darkBlue bg-cover bg-no-repeat"
            onClick={() => handleOnDeckClick(baseDeck.idDeck)}
            style={{
              backgroundImage: `url(${createBackgroundImage()})`,
            }}
          >
            <div className="flex justify-between w-full p-5 bg-darkBlue50 text-white">
              <div className="flex flex-col space-y-2 overflow-hidden">
                <div className="flex flex-col">
                  <h3 className="font-chillaxBold text-2xl whitespace-nowrap overflow-hidden text-ellipsis">
                    {baseDeck.name}
                  </h3>
                  <div className="flex space-x-1 whitespace-nowrap">
                    {mode && <p className="font-chillaxMedium">{t(mode)}</p>}
                    {hero && (
                      <>
                        <span>-</span>
                        <p className="font-chillaxRegular">
                          {hero?.name?.[currentLanguage]}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <Separator color="border-white" />
                <div className="flex pt-2 space-x-4 justify-start items-end">
                  {baseDeck.isPublic ? (
                    <FaLockOpen className="w-6 h-6" />
                  ) : (
                    <FaLock className="w-6 h-6" />
                  )}
                  {!!totalCards && (
                    <span className="pl-2 font-chillaxBold h-fit flex space-x-1 justify-center items-center">
                      <TbCardsFilled className="w-6 h-6" />
                      <p>{totalCards}</p>
                    </span>
                  )}
                </div>
              </div>
              <div
                className={`flex flex-col ${isCreator ? 'justify-between' : 'justify-end'} items-end`}
              >
                {isCreator && (
                  <RiDeleteBin2Fill
                    className="w-6 h-6 hover:scale-110 transition-all transition-150"
                    onClick={handleDeleteDeck}
                  />
                )}
                <span
                  className={`font-chillaxBold px-2 rounded whitespace-nowrap ${baseDeck.isLegal ? 'bg-green-600' : 'bg-red-600'}`}
                >
                  {isCreator &&
                    (baseDeck.isLegal ? t('deck.legal') : t('deck.illegal'))}
                </span>
              </div>
            </div>
          </div>
        </WhiteContainer>
      )}
    </>
  );
};

export default DeckPresentation;
