import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import { useTeam } from '../../hooks/api/useTeam';
import { RootState } from '../../store/store';

interface JoinTeamModalProperties {
  isOpen: boolean;
  closeModal: () => void;
}

const JoinTeamModal: React.FC<JoinTeamModalProperties> = ({
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [teamCode, setteamCode] = useState('');
  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const { joinTeam, isLoading, error } = useTeam();

  const user = useSelector((state: RootState) => state.user);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const result = await joinTeam({ teamCode, user });
    if (result) {
      setJoinedSuccessfully(true);
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="max-w-md"
      width="w-full"
    >
      <div className="space-y-8">
        <h2 className="text-xl font-chillaxBold mb-4">
          {t('actions.joinTeam')}
        </h2>
        {joinedSuccessfully ? (
          <p className="font-chillaxMedium">{t('teams.joinSuccess')}</p>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex items-end">
              <input
                type="text"
                id="createTeam"
                value={teamCode}
                placeholder={t('teams.joinTeamPlaceholder')}
                onChange={(event) => setteamCode(event.target.value)}
                className="block w-full shadow sm:text-sm rounded-l-md rounded-r-none h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
              />
              <Button isSubmit right disabled={isLoading}>
                {t('actions.join')}
              </Button>
            </div>
          </form>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <Button onClick={closeModal} full>
          {t('actions.close')}
        </Button>
      </div>
    </ModalTemplate>
  );
};

export default JoinTeamModal;
