import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '../components/Button';
import { CARDS_PATH } from '../constants/constants';
import { TABS_SHARED_CARDS } from '../constants/tabs';
import { useSharedCollection } from '../hooks/api/useSharedCollection';
import useAltCards from '../hooks/useAltCards';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import CompareModal from '../parts/modals/CompareModal';
import Tabs from '../parts/Tabs';
import { FullCard } from '../types/altCard';

const SharedCollection = () => {
  const { t } = useTranslation();
  const { sharedCode } = useParams<{ sharedCode: string }>();
  const { getSharedCollection } = useSharedCollection();
  const { recreateCardsFromText } = useAltCards('loading.loadingDeck');

  const [pseudo, setPseudo] = useState<string>();
  const [collectionCards, setCollectionCards] = useState<FullCard[]>([]);
  const [missingCards, setMissingCards] = useState<FullCard[]>([]);
  const [excessCards, setExcessCards] = useState<FullCard[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [isCompareModalOpen, setIsCompareModalOpen] = useState<boolean>(false);

  const handleOpenCompareModal = () => {
    setIsCompareModalOpen(true);
  };

  const handleCloseCompareModal = () => {
    setIsCompareModalOpen(false);
  };

  const { allCards } = useLoadCardsFromJson(CARDS_PATH);

  useEffect(() => {
    const fetchSharedCollection = async (sharedCode: string) => {
      setLoading(true);
      const response = await getSharedCollection(sharedCode);
      if (response) {
        const collectionText = response.cards || '';
        const missingCardsText = response.missingCards || '';
        const excessCardsText = response.excessCards || '';

        const parsedCollectionCards = await recreateCardsFromText({
          text: collectionText,
        });
        const parsedMissingCards = await recreateCardsFromText({
          text: missingCardsText,
        });
        const parsedExcessCards = await recreateCardsFromText({
          text: excessCardsText,
        });

        parsedCollectionCards && setCollectionCards(parsedCollectionCards);
        parsedMissingCards && setMissingCards(parsedMissingCards);
        parsedExcessCards && setExcessCards(parsedExcessCards);
        // TODO : Ajouter un call publique pour recupérer le pseudo
        setPseudo('User');
      }
      setLoading(false);
    };
    if (
      collectionCards.length > 0 ||
      missingCards.length > 0 ||
      excessCards.length > 0
    ) {
      return;
    } else if (sharedCode) fetchSharedCollection(sharedCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCards, collectionCards, missingCards, excessCards]);

  const availableTabs: Partial<typeof TABS_SHARED_CARDS> = {};

  for (const [key, value] of Object.entries(TABS_SHARED_CARDS)) {
    if (
      (key === TABS_SHARED_CARDS.COLLECTION.key &&
        collectionCards.length > 0) ||
      (key === TABS_SHARED_CARDS.MISSING.key && missingCards.length > 0) ||
      (key === TABS_SHARED_CARDS.EXCESS.key && excessCards.length > 0)
    ) {
      availableTabs[key as keyof typeof TABS_SHARED_CARDS] = value;
    }
  }

  const firstAvailableTab = Object.keys(availableTabs)[0];
  if (!selectedTab && firstAvailableTab) {
    setSelectedTab(firstAvailableTab);
  }

  if (loading) {
    return <Loading label={t('loading.loadingCollection')} />;
  }

  return (
    <Layout>
      <div className="px-6 lg:px-20">
        {Object.keys(availableTabs).length > 0 ? (
          <>
            <div className="flex justify-center items-center space-x-4 mb-8">
              <h1 className="text-3xl font-chillaxBold text-center text-darkBlue">
                {t('shared.title', { PSEUDO: pseudo })}
              </h1>

              <Button onClick={handleOpenCompareModal}>
                {t('actions.compareToMyCollection')}
              </Button>
            </div>
            <Tabs
              tabs={availableTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              full
            />
            {selectedTab === TABS_SHARED_CARDS.COLLECTION.key && (
              <CardList cards={collectionCards} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_CARDS.MISSING.key && (
              <CardList cards={missingCards} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_CARDS.EXCESS.key && (
              <CardList cards={excessCards} showCollectionFilter />
            )}
          </>
        ) : (
          <p className="text-center text-xl font-chillaxMedium">
            {t('errors.noSharedCollection')}
          </p>
        )}
        <CompareModal
          isOpen={isCompareModalOpen}
          onClose={handleCloseCompareModal}
          sharedMissingCards={missingCards}
          sharedExcessCards={excessCards}
        />
      </div>
    </Layout>
  );
};

export default SharedCollection;
