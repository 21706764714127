import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import Separator from '../../components/Separator';

interface UniqueCardsModalProperties {
  isOpen: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
  closeModal: () => void;
  loadWithUniques: (token: string) => void;
}

const UniqueCardsModal: React.FC<UniqueCardsModalProperties> = ({
  isOpen,
  isLoading,
  loadingLabel,
  closeModal,
  loadWithUniques,
}) => {
  const { t } = useTranslation();
  const [token, setToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLoadWithUniques = () => {
    if (token) {
      setErrorMessage('');
      loadWithUniques(token);
    } else {
      setErrorMessage(t('errors.noEnterToken'));
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="max-w-xl"
      width="w-full"
    >
      <div className="space-y-6">
        <div className="space-y-2">
          <h2 className="text-lg font-chillaxBold font-bold text-darkBlue">
            {t('uniqueModal.title')}
          </h2>
          <p className="font-chillaxMedium">
            <Trans i18nKey="uniqueModal.desc" />
          </p>
          {isLoading ? (
            <>
              <Separator />
              <div className="flex justify-center items-center py-2">
                <h3 className="font-chillaxBold text-darkBlue">
                  {loadingLabel || t('loading.loadingCards')}
                </h3>
              </div>
            </>
          ) : (
            <>
              {errorMessage && (
                <div className="flex">
                  <span className="text-red-600">
                    {loadingLabel || errorMessage}
                  </span>
                </div>
              )}
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder={t('actions.enterToken')}
                  value={token}
                  onChange={(event) => setToken(event.target.value)}
                  className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
                />
              </div>
              <div className="justify-center">
                <span className="text-red-600 font-chillaxMedium text-xs md:text-base">
                  <Trans i18nKey="warning.token" />
                </span>
              </div>
            </>
          )}
        </div>
        <div className="space-y-2">
          <div className="flex justify-start items-center space-x-4">
            <Button full onClick={handleLoadWithUniques}>
              {t('actions.fetchcard')}
            </Button>
            <Button full onClick={closeModal}>
              {t('actions.cancel')}
            </Button>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default UniqueCardsModal;
