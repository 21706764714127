import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import { useLogin } from '../../hooks/api/useLogin';

interface LoginModalProperties {
  isOpen: boolean;
  closeModal: () => void;
}

const LoginModal: React.FC<LoginModalProperties> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  //const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState('');
  const { login, error } = useLogin();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await login({ email, password, closeModal });
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="max-w-md"
      width="w-full"
    >
      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="space-y-4">
          <div>
            <label className="block mb-2 text-sm font-chillaxMedium text-darkBlue">
              {`${t('user.email')}*`}
            </label>
            <input
              type="email"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
          </div>

          <div>
            <label className="block mb-2 text-sm font-chillaxMedium text-darkBlue">
              {`${t('user.password')}*`}
            </label>
            <input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              value={password}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
            <span className="font-chillaxRegular text-red-600">{error}</span>
          </div>
        </div>
        <Button isSubmit full>
          {t('actions.login')}
        </Button>
      </form>
    </ModalTemplate>
  );
};

export default LoginModal;
