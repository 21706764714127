import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaCheckCircle } from 'react-icons/fa';
import { FaExclamationCircle } from 'react-icons/fa';
import { FaSave } from 'react-icons/fa';
import { FaLock, FaLockOpen } from 'react-icons/fa6';
import { IoStatsChart } from 'react-icons/io5';
import { LuFileEdit } from 'react-icons/lu';

import DeckSummary from '.././decks/DeckSummary';
import Button from '../../components/Button';
import Separator from '../../components/Separator';
import SeparatorVertical from '../../components/SeparatorVertical';
import { LANGUAGES } from '../../constants/constants';
import { exportOptions, exportToolsDeckOptions } from '../../data/exportData';
import i18n from '../../i18n';
import { FullCard } from '../../types/altCard';
import { DeckType } from '../../types/deck';
import { getNavigateUrl } from '../../utils/getNavigateUrl';
import ExportCards from '../ExportCards';
import Loading from '../Loading';

interface DeckBuilderHeaderProperties {
  deck: DeckType | undefined;
  baseDeckCards: FullCard[] | undefined;
  isDeckLoaded: boolean;
  isCreator: boolean;
  isLegal: boolean;
  handleSaveDeck: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setStatsPanelIsOpen: any;
  setUpdateDeckModalIsOpen: (isOpen: boolean) => void;
}
const DeckBuilderHeaderMobile = ({
  deck,
  baseDeckCards,
  isDeckLoaded,
  isCreator,
  isLegal,
  handleSaveDeck,
  setStatsPanelIsOpen,
  setUpdateDeckModalIsOpen,
}: DeckBuilderHeaderProperties) => {
  const { t } = useTranslation();

  const handleToggleStatsPanel = () =>
    setStatsPanelIsOpen((isOpen: boolean) => !isOpen);
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  if (!isDeckLoaded) {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.decks') + ' | Altverse';

  return (
    <div className="space-y-4">
      <div className="md:hidden flex flex-col items-center justify-center w-full space-y-2 p-2">
        <div className="h-fit w-full space-y-2">
          <div className="flex h-8 justify-center items-center gap-2">
            <Button small isLink to={getNavigateUrl(currentLanguage, 'decks')}>
              <div className="flex justify-center items-center space-x-2">
                <FaArrowLeft />
                <p>{t('actions.return')}</p>
              </div>
            </Button>
            <SeparatorVertical />
            <Button small onClick={handleToggleStatsPanel}>
              <div className="flex space-x-2 justify-center items-center w-full">
                <IoStatsChart />
              </div>
            </Button>
            {isCreator && (
              <Button small onClick={handleSaveDeck}>
                <FaSave />
              </Button>
            )}
            {deck && baseDeckCards && (
              <ExportCards
                cards={
                  deck.hero ? [deck.hero, ...baseDeckCards] : baseDeckCards
                }
                exportOptions={exportOptions}
                exportToolsOptions={exportToolsDeckOptions}
                isMobile
                small
              />
            )}
            <span className="w-2 h-1 rounded bg-darkBlue" />
            {isLegal !== undefined && (
              <span className={`text-xl md:text-3xl`}>
                {isLegal ? (
                  <FaCheckCircle className="text-green-600" />
                ) : (
                  <FaExclamationCircle className="text-red-600" />
                )}
              </span>
            )}
            <span className="text-xl">
              {deck?.isPublic ? <FaLockOpen /> : <FaLock />}
            </span>
          </div>
          <Separator />
          <div className="relative">
            <div className="flex flex-col justify-center items-center text-darkBlue">
              <p className="font-chillaxMedium">{deck?.name}</p>
              {deck?.hero && (
                <p className="font-chillaxBold">
                  {deck?.hero?.name?.[currentLanguage]}
                </p>
              )}
            </div>
            {isCreator && (
              <LuFileEdit
                className="absolute bottom-1 right-4"
                onClick={() => setUpdateDeckModalIsOpen(true)}
              />
            )}
          </div>
          <Separator />
        </div>
        <DeckSummary cards={baseDeckCards} small />
      </div>
    </div>
  );
};

export default DeckBuilderHeaderMobile;
