import React from 'react';

import { useTranslation } from 'react-i18next';

import { ValidationError } from '../hooks/useDeckValidation';
import { FullCard } from '../types/altCard';
import { particleIdToName } from '../utils/cardUtilities';

interface DeckValidationBannerProperties {
  allCards: FullCard[];
  isLegal: boolean;
  errors?: ValidationError[];
}

const DeckValidationBanner: React.FC<DeckValidationBannerProperties> = ({
  allCards,
  isLegal,
  errors,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  if (isLegal) {
    return <></>;
  }

  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-4 md:mx-0"
      role="alert"
    >
      <ul className="font-chillaxMedium text-xs md:text-base">
        {errors &&
          errors.map((error, index) => (
            <li key={index} className="mt-1">
              {t(`deck.errors.${error.code}`, { value: error.value })}
              {error.references && (
                <span className="font-chillaxBold">
                  {error.references
                    .map(
                      (reference) =>
                        `${reference.quantity} ${particleIdToName(allCards, reference.particuleId, currentLanguage)}`
                    )
                    .join(', ')}
                </span>
              )}
              {error.factions && (
                <span className="font-chillaxBold">
                  {error.factions
                    .map((faction) => `${faction.quantity} ${faction.faction}`)
                    .join(', ')}
                </span>
              )}
              {error.nbCards && (
                <span className="font-chillaxBold">{error.nbCards}</span>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DeckValidationBanner;
