import React from 'react';

interface WhiteContainerProperties {
  children: React.ReactNode;
  isSelected?: boolean;
  bottom?: boolean;
  top?: boolean;
  left?: boolean;
  right?: boolean;
}

const WhiteContainer: React.FC<WhiteContainerProperties> = ({
  children,
  isSelected,
  top,
  bottom,
  left,
  right,
}) => {
  const borderClasses = `
    ${bottom ? 'rounded-b-md border-t-0' : ''}
    ${top ? 'rounded-t-md border-b-0' : ''}
    ${left ? 'rounded-l-md border-r-0' : ''}
    ${right ? 'rounded-r-md border-l-0' : ''}
  `;
  return (
    <div
      className={`flex flex-col shadow-lg relative bg-lightBlue ${isSelected ? 'border-4' : 'border-2'} ${
        bottom || top || left || right ? borderClasses : 'rounded-lg'
      } border-darkBlue`}
    >
      {children}
    </div>
  );
};

export default WhiteContainer;
