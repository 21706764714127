import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../components/Button';
import { CARDS_PATH } from '../constants/constants';
import { useTeam } from '../hooks/api/useTeam';
import useAltCards from '../hooks/useAltCards';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import BackButton from '../parts/BackButton';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import MembersModal from '../parts/modals/MembersModal';
import Tabs, { Tab } from '../parts/Tabs';
import { RootState } from '../store/store';
import { FullCard } from '../types/altCard';
import { Member, TeamDetail } from '../types/team';
import { fusionSets } from '../utils/cardUtilities';
import { getNavigateUrl } from '../utils/getNavigateUrl';

const TeamDetailPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { idTeam } = useParams<{ idTeam: string }>();
  const { leaveTeam, deleteTeam, getTeamDetails, isLoading, error } = useTeam();
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [isMembersModalOpen, setIsMembersModalOpen] = useState<boolean>(false);
  const [team, setTeam] = useState<TeamDetail>();
  const navigate = useNavigate();
  const [selectedMember, setSelectedMember] = useState<Member>();
  const [selectedMemberCards, setSelectedMemberCards] = useState<FullCard[]>();
  const { recreateCardsFromText } = useAltCards('loading.loading');

  const user = useSelector((state: RootState) => state.user);

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards } = useLoadCardsFromJson(CARDS_PATH, isFusionEnabled);

  useEffect(() => {
    const fetchTeamDetails = async (idTeam: string) => {
      const teamAPI = await getTeamDetails({ idTeam, user });

      if (teamAPI && allCards) {
        const membersWithCards: Member[] = await Promise.all(
          teamAPI.members.map(async (member) => {
            const recreatedCards = await recreateCardsFromText({
              text: member.cards || '',
            });

            const fusedCards =
              isFusionEnabled && recreatedCards
                ? fusionSets(
                    recreatedCards.filter((card) =>
                      card.reference.includes('COREKS')
                    ),
                    recreatedCards.filter((card) =>
                      card.reference.includes('CORE_')
                    ),
                    allCards
                  )
                : recreatedCards;

            return {
              ...member,
              cards: fusedCards,
            };
          })
        );

        setTeam({
          ...teamAPI,
          members: membersWithCards,
        });

        // Initialiser l'onglet sur le premier membre
        if (membersWithCards.length > 0) {
          setSelectedTab(membersWithCards[0].idUser.toString());
        }
      }
    };

    if (idTeam && !team) {
      fetchTeamDetails(idTeam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTeam, team, allCards, isFusionEnabled]);

  useEffect(() => {
    if (team) {
      setSelectedMember(
        team.members.find(
          (member: Member) => member.idUser.toString() === selectedTab
        )
      );

      setSelectedMemberCards(selectedMember?.cards);
    }
  }, [selectedTab, isFusionEnabled, allCards, selectedMember, team]);

  const tabs: { [key: string]: Tab } = {};

  if (team) {
    for (const member of team.members) {
      tabs[member.idUser] = {
        key: `${member.idUser}`,
        label: member.pseudo,
      };
    }
  } else {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.teams') + `-${team?.name} | Altverse`;

  const goBackToTeams = () => {
    navigate(getNavigateUrl(i18n.language, 'teams'));
  };

  const handleLeave = async () => {
    const reponse = await leaveTeam({ idTeam: team.idTeam, user });
    if (reponse) goBackToTeams();
  };

  const handleDelete = async () => {
    const reponse = await deleteTeam({ idTeam: team.idTeam, user });
    if (reponse) goBackToTeams();
  };

  if (isLoading) {
    return <Loading label={t('loading.loading')} />;
  }

  if (!user?.idUser) {
    goBackToTeams();
  }

  return (
    <Layout>
      <div className="container mx-auto p-4">
        {error && <p className="text-red-500">{error}</p>}
        {team && (
          <div className="space-y-16">
            <div className="space-y-4">
              <BackButton to="teams" title={team.name} />
              <div className="flex w-full h-fit space-x-2">
                <Button full onClick={() => setIsMembersModalOpen(true)}>
                  {t('actions.members')}
                </Button>
                <Button full onClick={handleLeave}>
                  {t('actions.leaveTeam')}
                </Button>
                {user.idUser === team.createdBy && (
                  <Button full onClick={handleDelete}>
                    {t('actions.removeTeam')}
                  </Button>
                )}
              </div>
            </div>
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              full
            />
            {selectedMemberCards && (
              <CardList
                cards={selectedMemberCards}
                showCollectionFilter
                isExport
              />
            )}
          </div>
        )}
      </div>
      <MembersModal
        isOpen={isMembersModalOpen}
        closeModal={() => setIsMembersModalOpen(false)}
        team={team}
      />
    </Layout>
  );
};

export default TeamDetailPage;
