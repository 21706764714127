import axios from 'axios';

import { CardStatsInfo } from '../types/card';
import { TradeListCardAPI } from '../types/cardApi';
import { handleError } from '../utils/errorHandler';
import { formatCardsFriend } from '../utils/formatCards';

interface FetchFriendsCollectionCardsResult {
  transformedCards: CardStatsInfo[];
}

// const formatUniqueCardsFriend = (
//   cards: TradeListCardAPI[],
//   idUser: string
// ): FullCard[] => {
//   return cards
//     .filter((card) => isUnique(card.reference))
//     .map((card) => ({
//       reference: card.reference,
//       rarity: CARD_TYPE.UNIQUE,
//       owner: idUser,
//       lastUpdate: new Date().toISOString(),
//       name: {
//         en: card.name,
//         fr: card.name,
//         es: card.name,
//         it: card.name,
//         de: card.name,
//       },
//       imagePath: {
//         en: card.imagePath,
//         fr: card.imagePath,
//         es: card.imagePath,
//         it: card.imagePath,
//         de: card.imagePath,
//       },
//       inMyCollection: card.inMyCollection,
//       type: '',
//       subtypes: [],
//       mainFaction: '',
//       collectorNumberFormatted: {},
//     }));
// };

export const fetchFriendTradingCard = async (
  token: string,
  idUser: string,
  setErrorMessage: (error: string) => void
): Promise<FetchFriendsCollectionCardsResult | undefined> => {
  try {
    const response = await axios.get(
      'https://api.altered.gg/ownership_lists/tradelist/users/' + idUser,
      {
        params: {
          itemsPerPage: 500,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'fr-fr',
        },
      }
    );
    const transformedCards: CardStatsInfo[] = formatCardsFriend(
      response.data['hydra:member'] as TradeListCardAPI[]
    );
    // const uniqueCards: FullCard[] = formatUniqueCardsFriend(
    //   response.data['hydra:member'],
    //   idUser
    // );

    return { transformedCards };
  } catch (error) {
    console.error('Error fetching cards:', error);
    handleError(error, setErrorMessage);
    return undefined;
  }
};
