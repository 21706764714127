import { useEffect, useState } from 'react';

import { CARD_TYPE, RARITY, SET } from '../constants/constants';
import { FullCard, SortedCard } from '../types/altCard';
import { filterBySet } from '../utils/cardUtilities';

const dispatchCards = (cardsSorted: FullCard[]) => {
  const heroCards = cardsSorted.filter((card) => card.type === CARD_TYPE.HERO);
  const characterCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.CHARACTER
  );
  const spellCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.SPELL
  );
  const permanentCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.PERMANENT
  );
  const tokenCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.TOKEN
  );

  const commonCards = cardsSorted.filter(
    (card) => card.rarity === RARITY.COMMON && card.type !== CARD_TYPE.HERO
  );
  const rareCards = cardsSorted.filter((card) => card.rarity === RARITY.RARE);

  return {
    heroCards,
    characterCards,
    spellCards,
    permanentCards,
    tokenCards,
    commonCards,
    rareCards,
  };
};

const useLoadCardsFromJson = (
  cardDataPath: string,
  isFusionEnabled?: boolean,
  set?: string
) => {
  const [allCards, setAllCards] = useState<FullCard[]>();
  const [sortedCards, setSortedCards] = useState<SortedCard>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const fetchCards = async () => {
    try {
      const response = await fetch(cardDataPath);
      if (!response.ok) {
        throw new Error('Failed to fetch cards data');
      }

      const cardData = await response.json();
      const items = Object.values(cardData) as FullCard[];

      if (isFusionEnabled) {
        setAllCards(filterBySet(items, SET.BEYOND_THE_GATES));
      } else if (set) {
        setAllCards(filterBySet(items, set));
      } else {
        setAllCards(items);
      }
    } catch {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFusionEnabled, set]);

  useEffect(() => {
    if (allCards) {
      setSortedCards(dispatchCards(allCards));
    }
  }, [allCards]);

  return { allCards, sortedCards, loading, error };
};

export default useLoadCardsFromJson;
