import { useState } from 'react';

import api from '../../api/axiosInstance';
import { SharedCollection } from '../../types/shared';
import { User } from '../../types/user';
import { handleError } from '../../utils/errorHandler';

export interface CreateSharedCollectionProperties {
  user: User;
  cards?: string;
  missingCards?: string;
  excessCards?: string;
}

export const useSharedCollection = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createSharedCollection = async ({
    cards,
    missingCards,
    excessCards,
    user,
  }: CreateSharedCollectionProperties): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/shared',
        {
          cards,
          missingCards,
          excessCards,
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      if (response.status === 201) {
        setIsLoading(false);
        return response?.data?.sharedCode as string;
      } else {
        setIsLoading(false);
        setError('Erreur lors de la création de la collection partagée');
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setError('Erreur lors de la création de la collection partagée');
      setIsLoading(false);
      return undefined;
    }
  };

  // Récupérer une collection partagée
  const getSharedCollection = async (
    id: string
  ): Promise<SharedCollection | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/shared/${id}`);

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as SharedCollection;
      } else {
        setIsLoading(false);
        setError('Erreur lors de la récupération de la collection partagée');
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return { createSharedCollection, getSharedCollection, isLoading, error };
};
