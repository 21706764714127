import React from 'react';

import Modal from '@mui/material/Modal';
import { IoClose } from 'react-icons/io5';

interface ModalTemplateProperties {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  maxWidth?: string;
  maxHeight?: string;
  width?: string;
  height?: string;
}

const ModalTemplate: React.FC<ModalTemplateProperties> = ({
  isOpen,
  closeModal,
  children,
  maxWidth,
  maxHeight,
  width,
  height,
}) => {
  if (!isOpen) return <></>;

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black backdrop-blur-sm"
    >
      <div
        className={`relative overflow-hidden ${maxWidth ?? 'max-w-full'} ${maxHeight ?? 'max-h-full'} ${width} ${height} p-8 rounded-lg rounded-tr-none shadow-lg border-4 border-darkBlue bg-beige`}
      >
        <div
          className="absolute pl-1 pb-1 rounded-bl-lg right-0 top-0 cursor-pointer bg-darkBlue"
          onClick={closeModal}
        >
          <IoClose className="w-6 h-6 text-white" />
        </div>

        {children}
      </div>
    </Modal>
  );
};

export default ModalTemplate;
