import { useEffect, useState } from 'react';

import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useAltCards from './useAltCards';
import useDeckValidation from './useDeckValidation';
import {
  ACTIONS,
  CARDS_PATH,
  PRIMARY_SORT_MODE,
  RARITY,
  SECONDARY_SORT_MODE,
  SORT_DIRECTION,
} from '../constants/constants';
import { useDeck } from '../hooks/api/useDeck';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import useMyCards from '../hooks/useMyCards';
import i18n from '../i18n';
import { RootState } from '../store/store';
import { FullCard } from '../types/altCard';
import { DeckCardsType, DeckType } from '../types/deck';
import { getCardByReference, sortCards } from '../utils/cardUtilities';
import { extractNumber } from '../utils/common';
import { createDeckCards } from '../utils/deckUtilities';
import { createTextFromCollection } from '../utils/formatCards';
import { getNavigateUrl } from '../utils/getNavigateUrl';

const useDeckBuilder = () => {
  const { idDeck } = useParams();
  const navigate = useNavigate();

  const { fusionCards: myCards } = useMyCards(true);
  const { allCards } = useLoadCardsFromJson(CARDS_PATH, true);
  const user = useSelector((state: RootState) => state.user);
  const { getDeckDetail, updateDeck } = useDeck();

  const [deck, setDeck] = useState<DeckType>();
  const [deckCards, setDeckCards] = useState<DeckCardsType[]>([]);
  const [baseDeckCards, setBaseDeckCards] = useState<FullCard[] | undefined>(
    []
  );
  const [allCardsWithUniques, setAllCardsWithUniques] = useState<FullCard[]>(
    []
  );

  const { recreateCardsFromText } = useAltCards('loading.loadingDeck');

  const [isDeckLoaded, setIsDeckLoaded] = useState(false);

  const [selectedAction, setSelectedAction] = useState<string>(ACTIONS.ADD);
  const [selectedParticuleID, setSelectedParticuleID] = useState<string>('');

  const [cardsModalIsOpen, setCardsModalIsOpen] = useState(false);
  const [rarityModalIsOpen, setRarityModalIsOpen] = useState<boolean>(false);
  const [changeRarityModalIsOpen, setChangeRarityModalIsOpen] =
    useState<boolean>(false);

  const { isLegal, errors } = useDeckValidation(
    myCards,
    baseDeckCards,
    deck?.mode
  );

  const isCreator: boolean =
    !!deck?.createdBy && user?.idUser === extractNumber(deck?.createdBy);

  const goBackToDecks = () => navigate(getNavigateUrl(i18n.language, 'decks'));

  const handleSaveDeck = async () => {
    if (idDeck && baseDeckCards && deck) {
      const response = await updateDeck({
        deck,
        idDeck: Number.parseInt(idDeck),
        deckCards: createTextFromCollection(baseDeckCards, true),
        user,
        isLegal,
      });
      if (response) {
        toast.success(`${t('success.deckCreation')}`);
      } else {
        toast.warning(`${t('errors.deckCreation')}`);
      }
    }
  };

  const recreateDeckFromText = async (
    text: string
  ): Promise<FullCard[] | undefined> => {
    if (!allCards) return;

    const baseDeckCards = await recreateCardsFromText({
      text,
      myCards: deck?.allCards || myCards,
    });

    if (baseDeckCards) {
      setBaseDeckCards(baseDeckCards);
      return baseDeckCards;
    }

    return;
  };

  const handleCloseModal = (cardInput: string) => {
    if (cardInput) recreateDeckFromText(cardInput);
    setCardsModalIsOpen(false);
  };

  function changeCardRarity(particleId: string) {
    setSelectedParticuleID(particleId);
    setChangeRarityModalIsOpen(true);
  }

  function addDeckParticleId(particleId: string) {
    setSelectedAction(ACTIONS.ADD);
    setRarityModalIsOpen(true);
    setSelectedParticuleID(particleId);
  }

  function removeDeckParticleId(particleId: string) {
    setSelectedAction(ACTIONS.REMOVE);
    setRarityModalIsOpen(true);
    setSelectedParticuleID(particleId);
  }

  const handleCardAction = (cardId: string, action: string) => {
    setBaseDeckCards((previousBaseDeckCards) => {
      if (!previousBaseDeckCards) {
        return [];
      }

      let cardFound = false;

      const updatedBaseDeckCards = previousBaseDeckCards
        .map((deckCard) => {
          if (deckCard.reference === cardId) {
            cardFound = true;
            if (action === ACTIONS.ADD) {
              return {
                ...deckCard,
                quantity: (deckCard.quantity ?? 1) + 1,
              };
            } else if (
              action === ACTIONS.REMOVE &&
              (deckCard.quantity ?? 1) > 0
            ) {
              return {
                ...deckCard,
                quantity: (deckCard.quantity ?? 1) - 1,
              };
            }
          }
          return deckCard;
        })
        .filter((deckCard) => (deckCard.quantity ?? 1) > 0);

      if (!cardFound && action === ACTIONS.ADD) {
        const newcard = getCardByReference(cardId, allCardsWithUniques);

        if (newcard) {
          const deckCollection = deck?.allCards || myCards;
          const matchedCollectionCard =
            deckCollection &&
            deckCollection.find((card) => card.reference === newcard.reference);
          updatedBaseDeckCards.push({
            ...newcard,
            quantity: 1,
            inMyCollection: matchedCollectionCard?.inMyCollection,
          });
        }
      }

      return updatedBaseDeckCards;
    });

    setRarityModalIsOpen(false);
  };

  function updateDeckState(deck: DeckType) {
    setDeck(deck);
    setBaseDeckCards(deck?.cards);
    setIsDeckLoaded(true);
    if (
      user?.idUser === extractNumber(deck?.createdBy) &&
      ((deck && !deck?.cards) || deck?.cards?.length === 0)
    ) {
      setCardsModalIsOpen(true);
    } else {
      setCardsModalIsOpen(false);
    }
  }

  useEffect(() => {
    if (allCards && myCards) {
      setAllCardsWithUniques([
        ...allCards,
        ...myCards.filter((card) => card.rarity === RARITY.UNIQUE),
      ]);
    } else if (allCards) {
      setAllCardsWithUniques(allCards);
    }
  }, [allCards, myCards]);

  useEffect(() => {
    if (baseDeckCards && baseDeckCards.length > 0) {
      setBaseDeckCards(
        sortCards(
          baseDeckCards,
          PRIMARY_SORT_MODE.BY_TYPE,
          SECONDARY_SORT_MODE.BY_MAIN_COST,
          SORT_DIRECTION.ASCENDING,
          i18n.language
        )
      );
      setDeckCards(createDeckCards(baseDeckCards));
    } else {
      setDeckCards([]);
    }
  }, [baseDeckCards]);

  useEffect(() => {
    const fetchDecks = async (idDeck: number, allCards: FullCard[]) => {
      const deckDDB = await getDeckDetail({ user, idDeck });
      if (deckDDB) {
        const fullAllCards = deckDDB.allCards
          ? await recreateCardsFromText({
              text: deckDDB.allCards,
            })
          : undefined;
        const fullDeckCards = deckDDB.cards
          ? await recreateCardsFromText({
              text: deckDDB.cards,
              myCards: fullAllCards || myCards,
            })
          : undefined;
        const heroDeckCard = await getCardByReference(deckDDB.hero, allCards);

        const newDeck: DeckType = {
          idDeck: deckDDB.idDeck,
          isPublic: deckDDB.isPublic,
          isLegal: false,
          mode: deckDDB.mode,
          name: deckDDB.name,
          faction: deckDDB.faction,
          hero: heroDeckCard,
          cards: fullDeckCards,
          allCards: fullAllCards,
          createdBy: deckDDB.createdBy,
          createdAt: deckDDB.createdAt,
        };
        updateDeckState(newDeck);
      } else {
        goBackToDecks();
      }
    };
    if (
      idDeck &&
      typeof idDeck === 'string' &&
      !Number.isNaN(Number(idDeck)) &&
      allCards &&
      myCards
    ) {
      fetchDecks(Number.parseInt(idDeck), allCards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCards, idDeck, myCards]);

  return {
    isDeckLoaded,
    isCreator,
    isLegal,
    errors,
    deck,
    baseDeckCards,
    deckCards,
    selectedAction,
    selectedParticuleID,
    allCardsWithUniques,
    cardsModalIsOpen,
    rarityModalIsOpen,
    changeRarityModalIsOpen,
    setBaseDeckCards,
    handleCardAction,
    handleCloseModal,
    recreateDeckFromText,
    setChangeRarityModalIsOpen,
    handleSaveDeck,
    setRarityModalIsOpen,
    addDeckParticleId,
    removeDeckParticleId,
    updateDeckState,
    changeCardRarity,
    setCardsModalIsOpen,
  };
};

export default useDeckBuilder;
