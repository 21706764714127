import React from 'react';

import { useTranslation } from 'react-i18next';

import DynamicSortButtons from './DynamicSortButtons';
import ModalTemplate from './ModalTemplate';
import { FRIEND_SORT_MODE, SORT_DIRECTION } from '../constants/constants';

interface FriendFilterModalProperties {
  modalIsOpen: boolean;
  closeModal: () => void;
  sortMode: string;
  setSortMode: (mode: string) => void;
  sortDirection: string;
  setSortDirection: (direction: string) => void;
}

const FriendFilterModal: React.FC<FriendFilterModalProperties> = ({
  modalIsOpen,
  closeModal,
  sortMode,
  setSortMode,
  sortDirection,
  setSortDirection,
}) => {
  const { t } = useTranslation();

  const toggleSortDirection = () => {
    setSortDirection(
      sortDirection === SORT_DIRECTION.ASCENDING
        ? SORT_DIRECTION.DESCENDING
        : SORT_DIRECTION.ASCENDING
    );
  };

  const primarySortOptions = [
    {
      mode: FRIEND_SORT_MODE.BY_TRADE_CARDS,
      label: t('friendship.filter.tradeCards'),
      showIcon: true,
    },
    {
      mode: FRIEND_SORT_MODE.BY_LAST_UPDATE,
      label: t('friendship.filter.lastUpdate'),
      showIcon: true,
    },
    {
      mode: FRIEND_SORT_MODE.BY_TOTAL_TRADES,
      label: t('friendship.filter.totalTrades'),
      showIcon: true,
    },
  ];

  return (
    <ModalTemplate isOpen={modalIsOpen} closeModal={closeModal} width="w-full">
      <DynamicSortButtons
        sortMode={sortMode}
        setSortMode={setSortMode}
        sortDirection={sortDirection}
        toggleSortDirection={toggleSortDirection}
        options={primarySortOptions}
      />
    </ModalTemplate>
  );
};

export default FriendFilterModal;
