export const HERO = {
  RIN: {
    key: 'RIN',
    label: 'heroes.rin',
    reference: 'ALT_CORE_B_MU_03_C',
    faction: 'MU',
  },
  TEIJA: {
    key: 'TEIJA',
    label: 'heroes.teija',
    reference: 'ALT_CORE_B_MU_01_C',
    faction: 'MU',
  },
  ARJUN: {
    key: 'ARJUN',
    label: 'heroes.arjun',
    reference: 'ALT_CORE_B_MU_02_C',
    faction: 'MU',
  },
  SIERRA: {
    key: 'SIERRA',
    label: 'heroes.sierra',
    reference: 'ALT_CORE_B_AX_01_C',
    faction: 'AX',
  },
  TREYST: {
    key: 'TREYST',
    label: 'heroes.treyst',
    reference: 'ALT_CORE_B_AX_02_C',
    faction: 'AX',
  },
  SUBHASH: {
    key: 'SUBHASH',
    label: 'heroes.subhash',
    reference: 'ALT_CORE_B_AX_03_C',
    faction: 'AX',
  },
  KOJO: {
    key: 'KOJO',
    label: 'heroes.kojo',
    reference: 'ALT_CORE_B_BR_01_C',
    faction: 'BR',
  },
  ATSADI: {
    key: 'ATSADI',
    label: 'heroes.atsadi',
    reference: 'ALT_CORE_B_BR_02_C',
    faction: 'BR',
  },
  BASIRA: {
    key: 'BASIRA',
    label: 'heroes.basira',
    reference: 'ALT_CORE_B_BR_03_C',
    faction: 'BR',
  },
  FEN: {
    key: 'FEN',
    label: 'heroes.fen',
    reference: 'ALT_CORE_B_LY_03_C',
    faction: 'LY',
  },
  AURAQ: {
    key: 'AURAQ',
    label: 'heroes.auraq',
    reference: 'ALT_CORE_B_LY_02_C',
    faction: 'LY',
  },
  NEVENKA: {
    key: 'NEVENKA',
    label: 'heroes.nevenka',
    reference: 'ALT_CORE_B_LY_01_C',
    faction: 'LY',
  },
  WARU: {
    key: 'WARU',
    label: 'heroes.waru',
    reference: 'ALT_CORE_B_OR_02_C',
    faction: 'OR',
  },
  GULRANG: {
    key: 'GULRANG',
    label: 'heroes.gulrang',
    reference: 'ALT_CORE_B_OR_03_C',
    faction: 'OR',
  },
  SIGISMAR: {
    key: 'SIGISMAR',
    label: 'heroes.sigismar',
    reference: 'ALT_CORE_B_OR_01_C',
    faction: 'OR',
  },
  AKESHA: {
    key: 'AKESHA',
    label: 'heroes.akesha',
    reference: 'ALT_CORE_B_YZ_01_C',
    faction: 'YZ',
  },
  AFANAS: {
    key: 'AFANAS',
    label: 'heroes.afanas',
    reference: 'ALT_CORE_B_YZ_03_C',
    faction: 'YZ',
  },
  LINDIWE: {
    key: 'LINDIWE',
    label: 'heroes.lindiwe',
    reference: 'ALT_CORE_B_YZ_02_C',
    faction: 'YZ',
  },
};

export const MODES = {
  CONSTRUCTED: {
    key: 'CONSTRUCTED',
    label: 'modes.constructed',
  },
  SEALED: {
    key: 'SEALED',
    label: 'modes.sealed',
  },
  DRAFT: {
    key: 'DRAFT',
    label: 'modes.draft',
  },
  TWOVSTWO: {
    key: '2VS2',
    label: 'modes.2vs2',
  },
};
