import { useState } from 'react';

import { useDispatch } from 'react-redux';

import api from '../../api/axiosInstance';
import { setUser } from '../../store/slices/userSlice';
import { UserAPI } from '../../types/user';
import { handleError } from '../../utils/errorHandler';

interface LoginProperties {
  email: string;
  password: string;
  closeModal: () => void;
}

export const useLogin = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const login = async ({
    email,
    password,
    closeModal,
  }: LoginProperties): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post('/auth/login', { email, password });

      if (response.status !== 200) {
        setIsLoading(false);
        setError(response.data.error);
        return;
      }
      const userResponse: UserAPI = response.data;

      // Mise à jour de l'utilisateur dans le Redux store
      dispatch(setUser(userResponse));

      setIsLoading(false);
      closeModal();
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
    }
  };

  return { login, isLoading, error };
};
