import React from 'react';

import { useTranslation } from 'react-i18next';

import ModalTemplate from '../../components/ModalTemplate';
import Separator from '../../components/Separator';

interface LegalModalProperties {
  isOpen: boolean;
  onClose: () => void;
}

const LegalModal: React.FC<LegalModalProperties> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={onClose}
      width="lg:w-1/2"
      height="h-4/6"
    >
      <h2 className="text-2xl font-bold mb-4 font-chillaxMedium">
        {t('legal.title')}
      </h2>
      <div className="space-y-4 text-darkBlue font-chillaxRegular">
        <p
          dangerouslySetInnerHTML={{
            __html: t('legal.functionalityDescription'),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t('legal.developer'),
          }}
        />
        <h3 className="text-xl font-semibold">{t('legal.dataStorageTitle')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t('legal.dataStorageDescription'),
          }}
        />
        <h3 className="text-xl font-semibold">{t('legal.hostingTitle')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t('legal.hostingDescription'),
          }}
        />
        <h3 className="text-xl font-semibold">{t('legal.thanksTitle')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t('legal.thanksDescription'),
          }}
        />
        <h3 className="text-xl font-semibold">{t('legal.copyrightTitle')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t('legal.copyrightDescription'),
          }}
        />
        <h3 className="text-xl font-semibold">{t('legal.contactTitle')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t('legal.contactDescription'),
          }}
        />
        <Separator />
        <p>{t('legal.info')}</p>
      </div>
    </ModalTemplate>
  );
};

export default LegalModal;
