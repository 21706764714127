import { CARD_TYPE, SET } from '../constants/constants';
import { FullCard, SortedCard } from '../types/altCard';

const getRandomUniqueCardByFactionAndRarity = (
  cards: FullCard[],
  faction: string,
  types: string[],
  usedCardIds: Set<string>
): FullCard | undefined => {
  const filteredCards = cards.filter((card) => {
    return (
      (faction === '' || card.mainFaction === faction) &&
      types?.includes(card.type) &&
      !usedCardIds.has(card.reference)
    );
  });
  return filteredCards.length > 0
    ? filteredCards[Math.floor(Math.random() * filteredCards.length)]
    : undefined;
};

const drawFactionCards = (
  cards: FullCard[],
  factions: string[],
  types: string[],
  usedCardIds: Set<string>
): FullCard[] => {
  const selectedCards: FullCard[] = [];

  const firstFaction = Math.random() < 0.5 ? factions[0] : factions[1];
  const secondFaction =
    firstFaction === factions[0] ? factions[1] : factions[0];

  for (let index = 0; index < 2; index++) {
    const card = getRandomUniqueCardByFactionAndRarity(
      cards,
      firstFaction,
      types,
      usedCardIds
    );
    if (card) {
      selectedCards.push(card);
      usedCardIds.add(card.reference);
    }
  }

  const card = getRandomUniqueCardByFactionAndRarity(
    cards,
    secondFaction,
    types,
    usedCardIds
  );
  if (card) {
    selectedCards.push(card);
    usedCardIds.add(card.reference);
  }

  return selectedCards;
};

export const drawBoosterPack = (
  sortedCards: SortedCard | undefined,
  set: string
): FullCard[] => {
  const usedCardIds = new Set<string>();

  if (!sortedCards) return [];

  // Étape 1 : Tirage de la carte Héro
  const heroCard = getRandomUniqueCardByFactionAndRarity(
    sortedCards.heroCards,
    '',
    [CARD_TYPE.HERO],
    usedCardIds
  );

  // Étape 2 : Tirage de cartes rares
  const selectedRareCards = Array.from({ length: 3 }, () =>
    getRandomUniqueCardByFactionAndRarity(
      sortedCards.rareCards,
      '',
      [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
      usedCardIds
    )
  ).filter((card) => card !== undefined) as FullCard[];

  // Étape 3 : Tirage des cartes communes par factions
  const axiomBravoCards = drawFactionCards(
    sortedCards.commonCards,
    ['AX', 'BR'],
    [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
    usedCardIds
  );

  const munaLyraCards = drawFactionCards(
    sortedCards.commonCards,
    ['MU', 'LY'],
    [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
    usedCardIds
  );

  const ordisYzmirCards = drawFactionCards(
    sortedCards.commonCards,
    ['OR', 'YZ'],
    [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
    usedCardIds
  );

  const commonCards = [
    ...axiomBravoCards,
    ...munaLyraCards,
    ...ordisYzmirCards,
  ];

  // Étape 4 : Remplacement d'une carte commune par une rare uniquement si set != SET.BEYOND_THE_GATES
  if (set !== SET.BEYOND_THE_GATES && commonCards.length >= 2) {
    const replaceCommonWithRare = (replaceIndex: number) => {
      if (Math.random() < 0.5) {
        const commonCard = commonCards[replaceIndex];
        const rareCard = getRandomUniqueCardByFactionAndRarity(
          sortedCards.rareCards,
          commonCard.mainFaction,
          [CARD_TYPE.CHARACTER, CARD_TYPE.SPELL, CARD_TYPE.PERMANENT],
          usedCardIds
        );
        if (rareCard) {
          commonCards[replaceIndex] = rareCard;
        }
      }
    };

    const firstReplaceIndex = Math.floor(Math.random() * commonCards.length);
    let secondReplaceIndex;
    do {
      secondReplaceIndex = Math.floor(Math.random() * commonCards.length);
    } while (secondReplaceIndex === firstReplaceIndex);

    replaceCommonWithRare(firstReplaceIndex);
    replaceCommonWithRare(secondReplaceIndex);
  }

  // Étape 5 : Remplacement d'une rare par une unique si applicable
  // if (haveUnique && Math.random() < 0.15 && selectedRareCards.length > 0) {
  //   const replaceIndex = Math.floor(Math.random() * selectedRareCards.length);
  //   const rareCard = selectedRareCards[replaceIndex];
  //   const uniqueCard = getRandomUniqueCardByFactionAndRarity(
  //     sortedCards.uniqueCards,
  //     rareCard.mainFaction,
  //     [CARD_TYPE.CHARACTER],
  //     usedCardIds,
  //     currentLanguage
  //   );

  //   if (uniqueCard) {
  //     selectedRareCards[replaceIndex] = uniqueCard;
  //   }
  // }

  return [heroCard, ...selectedRareCards, ...commonCards].filter(
    (card) => card !== undefined
  ) as FullCard[];
};
