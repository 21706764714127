import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/Button';
import Separator from '../components/Separator';
import WhiteContainer from '../components/WhiteContainer';
import { useLogout } from '../hooks/api/useLogout';
import useAltCards from '../hooks/useAltCards';
import LoginModal from '../parts/modals/LoginModal';
import RegisterModal from '../parts/modals/RegisterModal';
import UniqueCardsModal from '../parts/modals/UniqueCardsModal';
import { setCollectionCards } from '../store/slices/collectionCardsSlice';
import { RootState } from '../store/store';
import { createTextFromBasicCards } from '../utils/formatCards';

const Profil = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logout } = useLogout();

  const user = useSelector((state: RootState) => state.user);

  const awaitingCards = useSelector(
    (state: RootState) => state.collectionCards.awaitingCards
  );
  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const { loadingLabel, loading, setLoading, recreateCardsFromText } =
    useAltCards('loading.loadingCards');

  const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false);
  const [uniqueModalIsOpen, setUniqueModalIsOpen] = useState(false);
  const [modalRegisterIsOpen, setModalRegisterIsOpen] = useState(false);

  const openModalLogin = () => setModalLoginIsOpen(true);
  const closeModalLogin = () => setModalLoginIsOpen(false);

  const openModalRegister = () => setModalRegisterIsOpen(true);
  const closeModalRegister = () => setModalRegisterIsOpen(false);

  const loadWithUniques = async (token: string) => {
    setLoading(true);
    const uniquesText = createTextFromBasicCards(awaitingCards);
    const uniquesCards = await recreateCardsFromText({
      text: uniquesText,
      token,
    });
    setLoading(false);
    if (uniquesCards) {
      dispatch(setCollectionCards([...myCards, ...uniquesCards]));
    }
    setUniqueModalIsOpen(false);
  };

  return (
    <div
      className={`pt-16 pb-8 w-full shadow-xl overflow-hidden border-l-4 border-darkBlue bg-beige`}
      style={{ height: '100vh' }}
    >
      <div className="relative flex flex-col space-y-4 md:space-y-10 md:justify-between h-full pt-10 pb-20 md:pl-10 p-4 md:pt-4 md:pb-4 font-chillaxMedium text-xs md:text-base">
        {user.accessToken && (
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-chillaxBold capitalize">
                {user?.pseudo}
              </h3>
              <h3>{user?.email}</h3>
            </div>
            <Separator />
            {user?.roles?.includes('market') && (
              <h3>You have access to the market</h3>
            )}
          </div>
        )}
        <Separator />
        {awaitingCards?.length > 0 && (
          <div className="flex justify-center w-full">
            <div className="flex flex-col w-fit">
              <WhiteContainer top isSelected>
                <div className="flex flex-col space-y-4 p-4 w-fit max-h-56 overflow-y-scroll">
                  <div>
                    <p className="font-chillaxBold text-lg">
                      {t('profil.awaitingDesc', {
                        nbCards: awaitingCards.length,
                      })}
                    </p>
                    <span className="text-sm">{t('profil.clickInfo')}</span>
                  </div>
                  <ul className="space-y-2">
                    {awaitingCards.map((card, index) => {
                      return (
                        <li
                          className="w-fitfont-chillaxMedium font-bold"
                          key={`awaitingCard-${index}`}
                        >
                          <a
                            href={`https://www.altered.gg/fr-fr/cards/${card.reference}`}
                            target="blank"
                          >
                            - {card.reference}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </WhiteContainer>
              <Button onClick={() => setUniqueModalIsOpen(true)} bottom>
                {t('actions.fetchcard')}
              </Button>
            </div>
          </div>
        )}
        <Separator />

        <div className="flex flex-col justify-center items-center space-y-2 h-screen">
          {user.accessToken ? (
            <div className="space-y-6">
              <Button onClick={logout}>{t('actions.logout')}</Button>
            </div>
          ) : (
            <>
              <Button onClick={openModalLogin}>{t('actions.login')}</Button>
              <Button onClick={openModalRegister}>
                {t('actions.register')}
              </Button>
            </>
          )}
        </div>
      </div>

      <LoginModal isOpen={modalLoginIsOpen} closeModal={closeModalLogin} />
      <RegisterModal
        isOpen={modalRegisterIsOpen}
        closeModal={closeModalRegister}
      />
      <UniqueCardsModal
        isOpen={uniqueModalIsOpen}
        isLoading={loading}
        loadingLabel={loadingLabel}
        closeModal={() => setUniqueModalIsOpen(false)}
        loadWithUniques={loadWithUniques}
      />
    </div>
  );
};

export default Profil;
