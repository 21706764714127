import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import { SEALED_SET } from '../constants/constants';

interface SealedActionsProperties {
  opened: boolean;
  handleOpenPacks: () => void;
  resetPacks: () => void;
  nbBooster: number;
  setNbBooster: (number: number) => void;
  set: string;
  setSet: (set: string) => void;
}

const SealedActions: React.FC<SealedActionsProperties> = ({
  opened,
  handleOpenPacks,
  resetPacks,
  nbBooster,
  setNbBooster,
  set,
  setSet,
}) => {
  const { t } = useTranslation();

  const handleResetAndOpenPacks = () => {
    resetPacks();
    handleOpenPacks();
  };

  return (
    <div className="flex flex-col justify-center relative">
      <div className="flex justify-center">
        <div className="flex">
          {/* Input for number of boosters */}
          <div className="relative">
            <label
              htmlFor="nbBooster"
              className="absolute -top-5 left-1 text-sm font-chillaxMedium text-darkBlue whitespace-nowrap"
            >
              {t('filterTitle.boosters')}
            </label>
            <input
              type="number"
              id="nbBooster"
              value={nbBooster}
              onChange={(event) => setNbBooster(Number(event.target.value))}
              min="1"
              max="10"
              className="block w-full max-w-36 shadow sm:text-sm font-chillaxBold rounded-l-md rounded-r-none h-9 p-5 outline-none border-4 bg-lightBlue border-darkBlue"
            />
          </div>

          {/* Select input for set */}
          <div className="relative">
            <label
              htmlFor="set"
              className="absolute -top-5 left-1 text-sm font-chillaxMedium text-darkBlue whitespace-nowrap"
            >
              {t('filterTitle.set')}
            </label>
            <select
              id="set"
              value={set}
              onChange={(event) => setSet(event.target.value)}
              className="block h-full w-full max-w-40 shadow sm:text-sm font-chillaxBold outline-none rounded-none border-4 border-l-0 bg-lightBlue border-darkBlue"
            >
              {Object.values(SEALED_SET).map((setItem) => (
                <option key={setItem.key} value={setItem.key}>
                  {t(setItem.label)}
                </option>
              ))}
            </select>
          </div>

          {/* Button */}
          <Button
            onClick={opened ? handleResetAndOpenPacks : handleOpenPacks}
            right
          >
            {opened ? t('actions.reopen') : t('actions.openPacks')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SealedActions;
