import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LanguageSelector from './LanguageSelector';
import { LANGUAGES } from '../constants/constants';
import { RootState } from '../store/store';
import { getNavigateUrl } from '../utils/getNavigateUrl';
import { linkTo } from '../utils/linkTo';

const NavBarMobile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const awaitingCards = useSelector(
    (state: RootState) => state.collectionCards.awaitingCards
  );

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isMenuOpen]);

  return (
    <nav className="md:hidden w-full z-50 fixed">
      <div className="relative shadow-xl px-4 py-4 flex justify-between items-center bg-darkBlue text-white z-50">
        <Link
          to={`${currentLanguage}/`}
          className="flex flex-col items-center absolute -bottom-8 right-1/2 w-2/4 lg:w-1/3"
          style={{
            transform: `translate(50%)`,
          }}
          onClick={closeMenu}
        >
          <span className="flex w-fit justify-center pb-4 px-10 text-xl font-bold font-chillaxBold uppercase whitespace-nowrap rounded-b-3xl shadow-2xl bg-darkBlue">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Altered Sealed Logo"
              className="h-10 w-auto object-contain transform transition-transform duration-200 hover:md:scale-105"
              height={40}
              width={40}
            />
          </span>
        </Link>

        <button
          className={`flex flex-col justify-center ${
            isMenuOpen ? 'items-center' : 'items-start'
          } p-2`}
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <div
            className={`w-8 h-1 bg-white rounded transition-transform duration-300 ease-in-out ${
              isMenuOpen ? 'rotate-45 translate-y-1.5' : ''
            }`}
          />
          <div
            className={`w-6 h-1 bg-white rounded transition-transform duration-300 ease-in-out mt-1 ${
              isMenuOpen ? 'w-8 -rotate-45 -translate-y-0.5' : ''
            }`}
          />
        </button>
        <LanguageSelector />
      </div>

      <div
        className={`fixed top-0 right-0 w-full h-full bg-darkBlue text-white transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out z-40`}
      >
        <div className="relative flex items-center justify-center p-4 h-full bg-darkBlue">
          <div
            className={`flex flex-col items-center justify-center space-y-8 font-chillaxBold text-4xl`}
          >
            <Link
              to={getNavigateUrl(i18n.language, 'cards')}
              className="hover:text-beige whitespace-nowrap hover:underline"
              onClick={toggleMenu}
            >
              {t('navigation.cards')}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, 'decks')}
              className="hover:text-beige whitespace-nowrap hover:underline"
              onClick={toggleMenu}
            >
              {t('navigation.decks')}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, 'teams')}
              className="hover:text-beige whitespace-nowrap hover:underline"
              onClick={toggleMenu}
            >
              {t('navigation.teams')}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, 'tools')}
              className="hover:text-beige whitespace-nowrap hover:underline"
              onClick={toggleMenu}
            >
              {t('navigation.tools')}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, 'market')}
              className="hover:text-beige whitespace-nowrap hover:underline"
              onClick={toggleMenu}
            >
              {t('navigation.market')}
            </Link>
            <Link
              to={getNavigateUrl(i18n.language, 'profil')}
              className="hover:text-beige whitespace-nowrap hover:underline"
              onClick={toggleMenu}
            >
              {awaitingCards?.length > 0 ? (
                <div className="relative">
                  <span className="flex justify-center items-center absolute -top-1 -left-3 w-5 h-5 rounded-full text-xs text-center font-chillaxBold bg-red-600 ">
                    1
                  </span>
                  <p className="font-chillaxBold">{t('navigation.profil')}</p>
                </div>
              ) : (
                <p className="font-chillaxBold">{t('navigation.profil')}</p>
              )}
            </Link>
          </div>
          <div className="fixed flex justify-center items-center font-chillaxMedium bottom-0 px-2 md:px-8 w-full text-center">
            <span>
              <Trans
                i18nKey="navigation.joinDiscord"
                components={{
                  LinkTo: linkTo({
                    dest: 'https://discord.gg/dus5VmXPW8',
                  }),
                  Bold: <strong />,
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBarMobile;
