import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import { useTeam } from '../../hooks/api/useTeam';
import { RootState } from '../../store/store';

interface CreateTeamModalProperties {
  isOpen: boolean;
  closeModal: () => void;
}

const CreateTeamModal: React.FC<CreateTeamModalProperties> = ({
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState('');
  const [teamCode, setteamCode] = useState<string | undefined>();
  const { createTeam, isLoading, error } = useTeam();

  const user = useSelector((state: RootState) => state.user);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const teamCode = await createTeam({ teamName, user });

    if (teamCode) {
      setteamCode(teamCode);
    }
  };

  if (!isOpen) return <></>;

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="max-w-md"
      width="w-full"
    >
      <div className="space-y-8">
        <h2 className="text-xl font-chillaxBold mb-4">
          {t('actions.createTeam')}
        </h2>
        {teamCode ? (
          <p className="font-chillaxMedium">
            {t('teams.createSuccess')} <strong>{teamCode}</strong>
          </p>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="flex items-end">
              <input
                type="text"
                id="createTeam"
                value={teamName}
                placeholder={t('teams.createTeamPlaceholder')}
                onChange={(event) => setTeamName(event.target.value)}
                className="block w-full shadow sm:text-sm rounded-l-md rounded-r-none h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
              />
              <Button isSubmit right disabled={isLoading}>
                {t('actions.create')}
              </Button>
            </div>
          </form>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <Button onClick={closeModal} full>
          {t('actions.close')}
        </Button>
      </div>
    </ModalTemplate>
  );
};

export default CreateTeamModal;
