import { useTranslation } from 'react-i18next';
import { CgClose } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/Button';
import Separator from '../components/Separator';
import {
  setCardsPerPage,
  setColumns,
  setCommonQuantity,
  setRareQuantity,
  toggleFoil,
  toggleFoilSidePanelCloseDefault,
  toggleFusion,
  toggleSidePanel,
} from '../store/slices/sidePanelSlice';
import { RootState } from '../store/store';

interface SidePanelOptionsProperties {
  handleShareCollection?: () => void;
  handleSelectCards?: () => void;
}

const SidePanelCollectionContent = ({
  handleShareCollection,
}: SidePanelOptionsProperties) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isSidePanelCloseDefault = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelCloseDefault
  );
  const columns = useSelector((state: RootState) => state.sidePanel.columns);
  const isFoilEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFoilEnabled
  );
  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );
  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );
  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );
  const cardsPerPage = useSelector(
    (state: RootState) => state.sidePanel.cardsPerPage
  );

  return (
    <div className="relative flex flex-col space-y-10 md:justify-between h-full pt-10 pb-20 md:pr-10 p-4 md:pt-4 md:pb-4 font-chillaxMedium text-xs md:text-sm overflow-y-auto">
      <div className="space-y-2 md:space-y-4">
        {handleShareCollection && (
          <>
            <div className="space-y-2">
              <p>{t('sidePanel.selectNumber')}</p>
              <div className="flex w-full space-x-4">
                <div className="flex flex-col">
                  <p className="font-chillaxBold">{t('gameElements.rares')}</p>
                  <input
                    type="number"
                    min="1"
                    value={rareQuantity || 3}
                    onChange={(event) =>
                      dispatch(setRareQuantity(Number(event.target.value)))
                    }
                    className="block w-full max-w-16 shadow sm:text-sm font-chillaxBold rounded-md h-9 p-2 outline-none border-4 bg-lightBlue border-darkBlue"
                  />
                </div>

                <div className="flex flex-col">
                  <p className="font-chillaxBold">
                    {t('gameElements.commons')}
                  </p>
                  <input
                    type="number"
                    min="1"
                    value={commonQuantity || 9}
                    onChange={(event) =>
                      dispatch(setCommonQuantity(Number(event.target.value)))
                    }
                    className="block w-full max-w-24 shadow sm:text-sm font-chillaxBold rounded-md h-9 p-2 outline-none border-4 bg-lightBlue border-darkBlue"
                  />
                </div>
              </div>
            </div>
            <Separator />
          </>
        )}

        <div className="space-y-2">
          {/* <p>{t('sidePanel.paramsDisplay')}</p> */}
          <div className="flex flex-col">
            <label htmlFor="cardsPerPage" className="font-chillaxBold">
              {t('sidePanel.columns')}
            </label>
            <input
              type="number"
              min="1"
              max="10"
              value={columns}
              onChange={(event) =>
                dispatch(setColumns(Number(event.target.value)))
              }
              className="block w-fit shadow sm:text-sm font-chillaxBold rounded-md h-9 p-2 outline-none border-4 bg-lightBlue border-darkBlue"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="cardsPerPage" className="font-chillaxBold">
              {t('sidePanel.cardsPerPage')}
            </label>
            <select
              id="cardsPerPage"
              value={cardsPerPage || 10}
              onChange={(event) =>
                dispatch(setCardsPerPage(Number.parseInt(event.target.value)))
              }
              className="w-36 shadow sm:text-sm font-chillaxBold rounded-md p-2 outline-none border-4 bg-lightBlue border-darkBlue"
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <Separator />
        <div className="space-y-2">
          {/* <p>{t('sidePanel.descFoil')}</p> */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={isFoilEnabled}
              onChange={() => dispatch(toggleFoil())}
              className="form-checkbox outline-none accent-darkBlue"
            />
            <span className="font-chillaxBold">
              {t('sidePanel.enableFoil')}
            </span>
          </label>
        </div>
        <div className="space-y-2">
          {/* <p>{t('sidePanel.descFusion')}</p> */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={isFusionEnabled}
              onChange={() => dispatch(toggleFusion())}
              className="form-checkbox outline-none accent-darkBlue"
            />
            <span className="font-chillaxBold">
              {t('sidePanel.enableFusion')}
            </span>
          </label>
        </div>
        <Separator />
        <div className="space-y-2">
          {/* <p>{t('sidePanel.descSidePanelDefault')}</p> */}
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={isSidePanelCloseDefault}
              onChange={() => dispatch(toggleFoilSidePanelCloseDefault())}
              className="form-checkbox outline-none accent-darkBlue"
            />
            <span className="font-chillaxBold">
              {t('sidePanel.sidePanelCloseDefault')}
            </span>
          </label>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center space-y-2">
        {handleShareCollection && (
          <Button onClick={handleShareCollection} full>
            {t('actions.shareCards')}
          </Button>
        )}
        <div className="md:hidden w-full space-y-2">
          <Separator />
          <Button onClick={() => dispatch(toggleSidePanel())} full>
            {t('actions.close')}
          </Button>
        </div>
      </div>
      <button
        onClick={() => dispatch(toggleSidePanel())}
        className="hidden md:block absolute top-1/2 transform -translate-y-1/2 right-0 py-3 p-1 rounded-l-md bg-darkBlue text-white text-xl"
      >
        <CgClose />
      </button>
    </div>
  );
};

export default SidePanelCollectionContent;
