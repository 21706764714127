import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../components/Button';
import ButtonWithConfirmation from '../components/ButtonWithConfirmation';
import { CARDS_PATH } from '../constants/constants';
import { TABS_CARDS, TABS_CARDS1, TABS_CARDS2 } from '../constants/tabs';
import { useCardInput } from '../hooks/useCardInput';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import useMyCards from '../hooks/useMyCards';
import DiscordTuto from '../parts/DiscordTuto';
import Layout from '../parts/Layout';
import CardSelectionModal from '../parts/modals/CardSelectionModal';
import ShareCollectionModal from '../parts/modals/ShareCollectionModal';
import RenderCardsList from '../parts/RenderCardsList';
import Tabs from '../parts/Tabs';
import { RootState } from '../store/store';
import { getExcessCards, getMissingCards } from '../utils/cardUtilities';

const AltCards = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);

  const {
    fusionCards: myCards,
    error,
    loading,
    updateRemoteCollection,
    recreateCollectionFromText,
  } = useMyCards();

  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_CARDS.MY_CARD.key
  );

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards: allCardsFusion } = useLoadCardsFromJson(
    CARDS_PATH,
    isFusionEnabled
  );
  const { allCards } = useLoadCardsFromJson(CARDS_PATH);

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );
  const myCardsEmpty = !(myCards?.length > 0);

  useEffect(() => {
    toast.warning(error);
  }, [error]);

  useEffect(() => {
    if (myCardsEmpty) {
      setSelectedTab(TABS_CARDS.CARDS.key);
    } else {
      setSelectedTab(TABS_CARDS.MY_CARD.key);
    }
  }, [myCardsEmpty]);

  const {
    cardInput,
    selectedCards,
    handleCardInputChange,
    setSelectedCards,
    setCardInput,
  } = useCardInput();

  const missingCards =
    myCards &&
    allCardsFusion &&
    getMissingCards(myCards, allCardsFusion, rareQuantity, commonQuantity);
  const excessCards =
    myCards && getExcessCards(myCards, rareQuantity, commonQuantity);

  const handleCardInput = async () => {
    if (!cardInput) {
      setErrorMessage(t('errors.invalidCardList'));
      return;
    }
    const collection = await recreateCollectionFromText(cardInput);
    if (collection && collection.length > 0) {
      updateRemoteCollection(collection);
    }
  };

  const handleCloseModal = () => {
    const selectedCardIds = selectedCards
      .map((card) => `${card.nbSelected || 1} ${card.reference}`)
      .join('\n');
    setCardInput(selectedCardIds);
    setModalIsOpen(false);
  };

  document.title = t('navigation.collection') + ' | Altverse';

  return (
    <Layout handleShareCollection={() => setShareModalIsOpen(true)}>
      <div className="space-y-10">
        <div className="flex justify-center items-center w-full">
          <div className="w-full items-start px-6 lg:px-20">
            <div className="flex flex-col md:flex-row space-x-2 justify-center items-center">
              <div className="space-y-2">
                <Button onClick={() => setModalIsOpen(true)} full>
                  {t('actions.selectCards')}
                </Button>
              </div>
              <span className="font-chillaxBold text-darkBlue">
                {t('connecter.or')}
              </span>
              <div className="flex flex-col w-full">
                {/* Error Message */}
                {errorMessage && (
                  <span className="text-red-600">{errorMessage}</span>
                )}
                <div className="flex justify-center w-full">
                  <textarea
                    rows={4}
                    placeholder={t('actions.enterYourCards')}
                    value={cardInput}
                    onChange={handleCardInputChange}
                    className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                  />
                  <ButtonWithConfirmation
                    onConfirm={handleCardInput}
                    confirmText={t('actions.confirm')}
                    cancelText={t('actions.cancel')}
                  >
                    {myCards && !myCardsEmpty
                      ? t('actions.refetchcard')
                      : t('actions.fetchcard')}
                  </ButtonWithConfirmation>
                </div>
              </div>
            </div>
            <DiscordTuto />
          </div>
        </div>
        {/* Cards List Section */}
        <div className="space-y-2 lg:space-y-8">
          {myCards && (
            <>
              <div className="hidden md:block">
                <Tabs
                  tabs={TABS_CARDS}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  small
                  full
                />
              </div>
              <div className="md:hidden space-y-2">
                <Tabs
                  tabs={TABS_CARDS1}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  small
                  full
                />
                <Tabs
                  tabs={TABS_CARDS2}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  small
                  full
                />
              </div>
            </>
          )}
          {loading ? (
            <span className="flex justify-center items-center m-6 font-chillaxBold text-lg">
              {t('loading.loadingCollection')}
            </span>
          ) : (
            <RenderCardsList
              selectedTab={selectedTab}
              myCards={myCards}
              allCards={allCards || []}
              missingCards={missingCards}
              excessCards={excessCards}
            />
          )}
        </div>
        {/* Modals */}
        <CardSelectionModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          cards={allCardsFusion || []}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          quantity
        />
        {/* A modifier, il faudrait pouvoir désactiver la fusion ici sans avoir les
        cartes manquante des différents sets */}
        {myCards && allCardsFusion && (
          <ShareCollectionModal
            isOpen={shareModalIsOpen}
            onClose={() => setShareModalIsOpen(false)}
            myCards={myCards}
            allCards={allCardsFusion}
          />
        )}
      </div>
    </Layout>
  );
};

export default AltCards;
