import React from 'react';

import CardList from './CardList';
import { TABS_CARDS } from '../constants/tabs';
import { FullCard } from '../types/altCard';

interface RenderCardsListProperties {
  selectedTab: string;
  allCards?: FullCard[];
  myCards?: FullCard[];
  missingCards?: FullCard[];
  excessCards?: FullCard[];
}

const RenderCardsList: React.FC<RenderCardsListProperties> = ({
  selectedTab,
  myCards,
  allCards,
  missingCards = [],
  excessCards = [],
}) => {
  if (selectedTab === TABS_CARDS.CARDS.key && allCards) {
    return <CardList cards={allCards} isExport />;
  }
  if (selectedTab === TABS_CARDS.MY_CARD.key && myCards) {
    return <CardList cards={myCards} showCollectionFilter isExport />;
  }

  if (selectedTab === TABS_CARDS.MISSING.key && missingCards?.length > 0) {
    return <CardList cards={missingCards} showCollectionFilter isExport />;
  }

  if (selectedTab === TABS_CARDS.EXCESS.key && excessCards?.length > 0) {
    return <CardList cards={excessCards} showCollectionFilter isExport />;
  }

  return <></>;
};

export default RenderCardsList;
