import React from 'react';

import { t } from 'i18next';
import { FaCheckCircle } from 'react-icons/fa';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import { useCardInputAdvanced } from '../../hooks/useCardInputAdvanced';
import { FullCard } from '../../types/altCard';
import CardList from '../CardList';

interface CardSelectionModalProperties {
  isOpen: boolean;
  onClose: (cardInput: string) => void;
  cards: FullCard[];
  currentCards?: string;
  quantity?: boolean;
  faction?: string;
}

const CardSelectionModal: React.FC<CardSelectionModalProperties> = ({
  isOpen,
  onClose,
  cards,
  quantity = false,
  currentCards,
  faction,
}) => {
  const { cardInput, selectedCards, handleCardInputChange, setSelectedCards } =
    useCardInputAdvanced(cards, currentCards);

  const handleConfirmSelection = () => {
    onClose(cardInput);
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={handleConfirmSelection}
      width="lg:w-2/3"
      height="h-5/6"
    >
      <div className="flex flex-col justify-center w-full p-4">
        <h2 className="text-xl font-chillaxBold">
          {t('selectCard.importTitle')}
        </h2>
        <textarea
          rows={4}
          placeholder={t('actions.enterYourDeckList')}
          value={cardInput}
          onChange={handleCardInputChange}
          className="block w-full shadow sm:text-xs p-1 rounded-md border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
        />
      </div>
      <div className="h-full overflow-y-scroll pb-40 md:pb-36">
        <CardList
          cards={cards}
          setSelectedCards={setSelectedCards}
          handleClose={handleConfirmSelection}
          selectedCards={selectedCards}
          quantity={quantity}
          faction={faction}
          isExchange
          isDeckBuilder
        />
      </div>
      <div className="absolute bottom-0 left-0 w-full h-10 z-40 bg-beige">
        <div className="relative flex justify-center w-full">
          <div className="absolute -top-5 w-5/6 flex justify-center">
            <Button onClick={handleConfirmSelection} isShadow>
              <div className="flex space-x-2 justify-center items-center w-full">
                <FaCheckCircle />
                <p>{t('actions.validate')}</p>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default CardSelectionModal;
