import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import { CARDS_PATH, VISIBILITY } from '../../constants/constants';
import { HERO, MODES } from '../../constants/decks';
import { useDeck } from '../../hooks/api/useDeck';
import useLoadCardsFromJson from '../../hooks/useLoadCardsFromJson';
import { RootState } from '../../store/store';
import { FullCard } from '../../types/altCard';
import { DeckType } from '../../types/deck';
import { getCardByReference } from '../../utils/cardUtilities';
import { createTextFromCollection } from '../../utils/formatCards';

interface ModifyDeckModalProperties {
  deck: DeckType;
  isOpen: boolean;
  isLegal: boolean;
  closeModal: () => void;
  updateDeckState: (deck: DeckType) => void;
}

const ModifyDeckModal: React.FC<ModifyDeckModalProperties> = ({
  deck,
  isOpen,
  isLegal,
  closeModal,
  updateDeckState,
}) => {
  const { t } = useTranslation();

  const [deckName, setDeckName] = useState<string>(deck.name || '');
  const [deckHero, setDeckHero] = useState<FullCard | undefined>(deck.hero);
  const [isPublic, setIsPublic] = useState<boolean>(deck.isPublic);
  const { updateDeck, isLoading } = useDeck();
  const { allCards } = useLoadCardsFromJson(CARDS_PATH, true);

  const user = useSelector((state: RootState) => state.user);

  const handleHeroChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHero =
      allCards && getCardByReference(event.target.value, allCards);

    if (selectedHero) {
      setDeckHero(selectedHero);
    }
  };

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === VISIBILITY.PRIVATE) {
      setIsPublic(false);
    } else if (selectedValue === VISIBILITY.PUBLIC) {
      setIsPublic(true);
    } else {
      setIsPublic(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (deck.cards) {
      const updatedDeck = await updateDeck({
        user,
        deck,
        deckName,
        deckHero: deckHero?.reference,
        isPublic: isPublic,
        isLegal: isLegal,
        idDeck: deck.idDeck,
        deckCards: createTextFromCollection(deck.cards, true),
      });
      if (updatedDeck) {
        updateDeckState({ ...deck, name: deckName, hero: deckHero, isPublic });
        closeModal();
      }
    }
  };

  if (!isOpen) return <></>;

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="max-w-md"
      width="w-full"
    >
      <div className="space-y-8">
        <h2 className="text-xl font-chillaxBold mb-4">
          {t('deck.modifyDeck')}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-4">
            <input
              type="text"
              id="createDeckName"
              value={deckName}
              placeholder={t('deck.createDeckPlaceholder')}
              onChange={(event) => setDeckName(event.target.value)}
              className="block w-full shadow sm:text-sm rounded-md h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />

            {deck.mode === MODES.CONSTRUCTED.key && (
              <select
                id="createDeckHero"
                value={deckHero?.reference}
                onChange={handleHeroChange}
                className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
              >
                <option value="" disabled>
                  {t('deck.selectHeroPlaceholder')}
                </option>
                {Object.values(HERO)
                  .filter(
                    (hero) => hero.faction && hero.faction === deck.faction
                  )
                  .map((hero) => (
                    <option key={hero.key} value={hero.reference}>
                      {t(hero.label)}
                    </option>
                  ))}
              </select>
            )}

            <select
              id="createDeckTeam"
              value={isPublic ? VISIBILITY.PUBLIC : VISIBILITY.PRIVATE}
              onChange={handleTeamChange}
              className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
            >
              <option value={VISIBILITY.PRIVATE}>{t('deck.private')}</option>
              <option value={VISIBILITY.PUBLIC}>{t('deck.public')}</option>
            </select>

            <Button isSubmit disabled={isLoading}>
              {t('actions.modify')}
            </Button>
          </div>
        </form>
        {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
      </div>
    </ModalTemplate>
  );
};

export default ModifyDeckModal;
