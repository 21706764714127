import React from 'react';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import DeckCardActions from './DeckCardActions';
import Card from '../../components/Card';
import { LANGUAGES } from '../../constants/constants';
import { ValidationError } from '../../hooks/useDeckValidation';
import { FullCard } from '../../types/altCard';
import { DeckCardsType } from '../../types/deck';
import { particleIdToName } from '../../utils/cardUtilities';
import { getTotalCardCount } from '../../utils/deckUtilities';

export const calculateTotalQuantityForParticleId = (
  errors: ValidationError[],
  particleId: string
): number => {
  return errors
    .flatMap((error) => error.references || [])
    .filter((reference) => reference.particuleId === particleId)
    .reduce((total, reference) => total + (reference.quantity || 0), 0);
};

interface DeckCardListProperties {
  cards: DeckCardsType[];
  allCards: FullCard[];
  isCreator?: boolean;
  errors?: ValidationError[] | undefined;
  addDeckParticulId: (particleId: string) => void;
  removeDeckParticulId: (particleId: string) => void;
  changeRarity: (particleId: string) => void;
}

const DeckCardList: React.FC<DeckCardListProperties> = ({
  cards,
  allCards,
  isCreator,
  errors,
  addDeckParticulId,
  removeDeckParticulId,
  changeRarity,
}) => {
  const { i18n } = useTranslation();
  const overlapOffset = 20;
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  return (
    <div className="container mx-auto p-4 space-y-4 md:space-y-24">
      <div className="flex flex-wrap justify-center items-start w-full gap-10">
        {cards.map((deckCard, deckIndex) => {
          let unifiedIndex = -1;
          const totalCards = getTotalCardCount(deckCard);
          const name = particleIdToName(
            allCards,
            deckCard.particleId,
            currentLanguage
          );
          const missingQuantity =
            (errors &&
              calculateTotalQuantityForParticleId(
                errors,
                deckCard.particleId
              )) ||
            undefined;

          return (
            <div
              key={`${deckCard.particleId}-${deckIndex}`}
              className="grid grid-cols-1 gap-y-3 w-32 md:w-52"
            >
              <h3
                className={`${missingQuantity ? 'text-red-800' : 'text-darkBlue'} text-center font-chillaxBold whitespace-nowrap overflow-hidden text-ellipsis`}
              >
                {name}
                {missingQuantity && <span> ({missingQuantity})</span>}
              </h3>

              <div>
                {isCreator && (
                  <DeckCardActions
                    particleId={deckCard.particleId}
                    addDeckParticulId={addDeckParticulId}
                    removeDeckParticulId={removeDeckParticulId}
                    changeRarity={changeRarity}
                  />
                )}
                <div
                  className="relative w-full"
                  style={{
                    height: `${totalCards * overlapOffset + (isMobile ? 152 : 273)}px`,
                  }}
                >
                  {deckCard.cards.flatMap((card) => {
                    return Array.from({ length: card.quantity || 1 }).map(
                      () => {
                        unifiedIndex += 1;
                        return (
                          <div
                            key={`${card.reference}-${unifiedIndex}`}
                            className="absolute transition-transform duration-300"
                            style={{
                              bottom: `${unifiedIndex * overlapOffset}px`,
                              zIndex: deckCard.cards.length * 10 - unifiedIndex,
                            }}
                          >
                            <Card card={card} />
                          </div>
                        );
                      }
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeckCardList;
