import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import Filter from '../../components/Filter';
import ModalTemplate from '../../components/ModalTemplate';
import Separator from '../../components/Separator';
import {
  factionData,
  legalityData,
  modeData,
  visibilityData,
} from '../../data/filtersData';

interface FilterDecksModalProperties {
  modalIsOpen: boolean;
  closeModal: () => void;
  deckFaction: string[];
  setDeckFaction: (array: string[]) => void;
  deckVisibility: string;
  setDeckVisibility: (array: string) => void;
  deckLegality: string;
  setDeckLegality: (array: string) => void;
  deckMode: string[];
  setDeckMode: (array: string[]) => void;
}

const FilterDecksModal: React.FC<FilterDecksModalProperties> = ({
  modalIsOpen,
  closeModal,
  deckFaction,
  setDeckFaction,
  deckVisibility,
  setDeckVisibility,
  deckLegality,
  setDeckLegality,
  deckMode,
  setDeckMode,
}) => {
  const { t } = useTranslation();

  const resetFilters = () => {
    setDeckFaction([]);
    setDeckVisibility('');
    setDeckLegality('');
  };

  return (
    <ModalTemplate isOpen={modalIsOpen} closeModal={closeModal}>
      <div className={'space-y-4'}>
        <div className={'grid grid-cols-2 gap-8'}>
          <Filter
            id="deckFaction"
            label={t('filterTitle.rarity')}
            value={deckFaction}
            onChange={(value) => setDeckFaction(value as string[])}
            options={factionData}
            multiSelect
          />
          <Filter
            id="deckMode"
            label={t('filterTitle.rarity')}
            value={deckMode}
            onChange={(value) => setDeckMode(value as string[])}
            options={modeData}
            multiSelect
          />
          <Filter
            id="deckVisibility"
            label={t('filterTitle.rarity')}
            value={[deckVisibility]}
            onChange={(value) => setDeckVisibility(value as string)}
            options={visibilityData}
          />
          <Filter
            id="deckLegality"
            label={t('filterTitle.rarity')}
            value={[deckLegality]}
            onChange={(value) => setDeckLegality(value as string)}
            options={legalityData}
          />
        </div>
        <Separator />
        <Button onClick={closeModal} full>
          {t('actions.validate')}
        </Button>
        <Button onClick={resetFilters} full>
          {t('actions.reset')}
        </Button>
      </div>
    </ModalTemplate>
  );
};

export default FilterDecksModal;
