import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import DeckFilters from './DeckFilters';
import DeckPresentation from '../../parts/decks/DeckPresentation';
import { Deck_DDB } from '../../types/deck';

interface DeckListProperties {
  decks: Deck_DDB[];
  refreshDecks: (idDeck: number) => void;
}

const DeckList = ({ decks, refreshDecks }: DeckListProperties) => {
  const { t } = useTranslation();
  const [filteredDecks, setFilteredDecks] = useState<Deck_DDB[]>([]);

  document.title = t('navigation.decks') + ' | Altverse';

  return (
    <div className="flex flex-col space-y-14">
      <DeckFilters
        decks={decks}
        filteredDecks={filteredDecks}
        setFilteredDecks={setFilteredDecks}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredDecks.map((deck, index) => (
          <div key={`privatedeck-${index}`}>
            <DeckPresentation baseDeck={deck} refreshDecks={refreshDecks} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeckList;
